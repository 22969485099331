import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import UIkit from 'uikit';
import { ScreenText } from '@/lang/ScreenText';
import { ErrorMessages } from '@/lang/errorText';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component
export class ErrorPopUpComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    private objErrorMessages : ErrorMessages =new ErrorMessages();
    public errorMessages : any = {};
    public bellxcelHelpUrl : string ='';
    public mobileView:boolean = false;

    @Prop()
    errorResponse: any;

    isMobileCheck() {
      if (APP_UTILITIES.isMobile()) {
        this.mobileView = true;
      }
      else {
        this.mobileView = false;
      }
    }
    mounted() {
      window.addEventListener('resize', this.isMobileCheck);
      const popupRef = <HTMLInputElement> document.getElementById('technical-error-msg');
      popupRef&&popupRef.addEventListener(APP_CONST.EVENT_CLICK, this.closePopup);
      this.bellxcelHelpUrl= this.objScreenText.getScreenText('LABEL_BELLXCEL_HELP');
    }

    destroyed() {
      window.removeEventListener('resize', this.isMobileCheck);
    }

    @Watch('errorResponse',{deep: true})
    onChildChanged(val: any) {
      this.errorMessages = this.getErrorMessages(this.errorResponse.name);
      if (val && this.errorResponse && this.errorMessages) {
        UIkit.modal('#technical-error-msg').show();
        this.isMobileCheck();
        this.$emit(APP_CONST.NO_ACTION, true);
      }
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    public getErrorMessages(key: string): string {
      return this.objErrorMessages.getErrorMessages(key);
    }
    public closePopup() {
      this.$emit(APP_CONST.NO_ACTION, false);
      /* istanbul ignore else */
      if (this.errorResponse.name === 'Error_InvalidToken' || this.errorResponse.name === 'Error_TokenMultipleInstance' || this.errorResponse.name === 'Error_TokenValidityExpired') {
        const userName = APP_UTILITIES.getCookie('username');
        APP_UTILITIES.removeCookies();
        if (userName) {
          APP_UTILITIES.setCookie(APP_CONST.USERNAME,userName,APP_CONST.ONE);
        }
        window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
      }
      if (UIkit.modal('#technical-error-msg')) {
        UIkit.modal('#technical-error-msg').hide();
      }
    }

}
