import Vue from 'vue';
import VueRouter, { Route, RouteConfig, RouteRecord } from 'vue-router';
import routes from './route';
import APP_UTILITIES from '@/utilities/commonFunctions';
import UIkit from 'uikit';
import vueCountryRegionSelect from 'vue-country-region-select';
import APP_CONST from '@/constants/AppConst';
import { getInstance } from '@/auth';
import store from '@/store';
import { AnalyticsConfig } from '@/analytics';

Vue.use(vueCountryRegionSelect);
Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const closeAllModal = () => {
  const model = UIkit.modal('.uk-modal');
  if (model) {
    model.hide();
    const nodes = document.querySelectorAll('.uk-modal');
    const textArr: any = [];
    if (nodes.length) {
      nodes.forEach((d: any, i) => {
        if (textArr.indexOf(d.innerText.replace(/[\r\n]+/gm, '')) > -1) {
          d.remove();
        }
        else {
          textArr.push(d.innerText);
        }
        if (d && d.classList.contains('uk-open')) {
          d.removeAttribute('style');
          d.classList.remove('uk-open');
          d.style.position = 'relative';
        }
      });
    }
  }
};

router.beforeEach(async (to, from, next) => {
  const name = to.name
    ? to.name
    : 'BellXcel';
  document.title = to.meta.name
    ? to.meta.name
    : name;

  const isAuthRedirect =
    to.hasOwnProperty('query') && to.query.hasOwnProperty('authRedirect');

  const authService = getInstance();
  if (authService) {
    // if auth client is undefined we initialize it for its usage
    if (!(await authService.getAuthClient())) {
      await authService.iniAuth();
    }

    const silent = await authService.getTokenSilently();

    // Cookies need to be set here only in the first render of the page
    if (isAuthRedirect) {
      APP_UTILITIES.setUserCookies(silent, true);
    }
  }

  const { roleId } = APP_UTILITIES.coreids();

  // Handle wether or not to preserve the catalog filter data between navigations
  // If we are navigating to catalog from a landing page, we DONT want to reset the filter data,
  // Otherwise, we do want to reset the filter data
  const landingPages = [
    APP_CONST.APP_ROUTES.CMS_COURSE_LANDING.name,
    APP_CONST.APP_ROUTES.CMS_ACTIVITY_LANDING.name,
    APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LANDING.name,
    APP_CONST.APP_ROUTES.CMS_ROUTINE_LANDING.name,
    APP_CONST.APP_ROUTES.CMS_STRATEGY_LANDING.name,
  ];
  const catalogPage = APP_CONST.APP_ROUTES.CMS_CATALOG.name;
  if (
    to &&
    to.name &&
    to.name === catalogPage &&
    from &&
    from.name &&
    !landingPages.includes(from.name)
  ) {
    store.dispatch('catalogFilterStore/resetCatalogFilterDataAction', true);
  }

  const hasAccessToUserManagement = APP_CONST.ALLOWED_ROLES_FOR_USER_MANAGEMENT.includes(roleId);

  closeAllModal();

  if (isAuthRedirect) {
    next(to.path);
  }
  else if (hasAccessToUserManagement) {
    next();
  }
  else if (!APP_CONST.USER_ACCESS_GROUP[roleId].includes(to.path)
      && (!to.name
        || (to.name && !APP_CONST.USER_ACCESS_GROUP[roleId].includes(to.name)))) {
    next(false);
  }
  else {
    next();
  }
});

export default router;

export interface AppRoute extends Route {
  meta?: AppRouteMeta;
}

export interface AppRouteRecord extends RouteRecord {
  meta: AppRouteMeta;
}

export type AppRouteConfig = Omit<RouteConfig, 'meta' | 'children'> & {
  children?: AppRouteConfig[];
  meta?: AppRouteMeta;
};

export interface AppRouteMeta {
  name?: string;
  gtm?: string;
  reuse?: boolean;
  excludeDefaultBackgroundPatterns?: boolean;
  flag?: string;
  analytics?: AnalyticsConfig;
}
