import idb from './idb';
const BELL_STORE = 'bellxcel-store';
const ACCOUNT_LIST = 'account-list';
const AUTH_DATA = 'auth-data';
const PROFILE = 'profile';
const ACCOUNT = 'Account';
const CLASS_ROOMS = 'classrooms';

export const dbPromise = idb.open(BELL_STORE, 1, function (db) {
  if (!db.objectStoreNames.contains(ACCOUNT_LIST)) {
    db.createObjectStore(ACCOUNT_LIST);
  }
  if (!db.objectStoreNames.contains(AUTH_DATA)) {
    db.createObjectStore(AUTH_DATA, { keyPath: 'id' });
  }
  if (!db.objectStoreNames.contains(PROFILE)) {
    db.createObjectStore(PROFILE, { keyPath: 'id' });
  }
  if (!db.objectStoreNames.contains(ACCOUNT)) {
    db.createObjectStore(ACCOUNT);
  }
  if (!db.objectStoreNames.contains(CLASS_ROOMS)) {
    db.createObjectStore(CLASS_ROOMS);
  }
});

export const writeData = (st, data) => {
  return dbPromise
    .then(function (db) {
      const tx = db.transaction(st, 'readwrite');
      const store = tx.objectStore(st);
      store.put(data);
      return tx.complete;
    });
};

export const writeDataByKey = (st,key, data) => {
  return dbPromise
    .then(function (db) {
      const tx = db.transaction(st, 'readwrite');
      const store = tx.objectStore(st);
      store.put(data, key);
      return tx.complete;
    });
};

export const readDataByKey = (st, key) => {
  return dbPromise
    .then(function (db) {
      const tx = db.transaction(st, 'readonly');
      const store = tx.objectStore(st);
      return store.get(key);
    });
};

export const readAllData = (st) => {
  return dbPromise
    .then(function (db) {
      const tx = db.transaction(st, 'readonly');
      const store = tx.objectStore(st);
      return store.getAll();
    });
};

export const clearAllData = (st) => {
  return dbPromise
    .then(function (db) {
      const tx = db.transaction(st, 'readwrite');
      const store = tx.objectStore(st);
      store.clear();
      return tx.complete;
    });
};

export const deleteItemFromData = (st, id) => {
  dbPromise
    .then(function (db) {
      const tx = db.transaction(st, 'readwrite');
      const store = tx.objectStore(st);
      store.delete(id);
      return tx.complete;
    })
    .then(function () {
    });
};