<style lang="less">
@import "./App.less";
</style>

<template>
  <div
    v-if="isAuthTokenSet && isAppReady"
    id="app"
    :class="['app', pointerEventNone && 'pointer']"
  >
    <div
      v-show="shouldDisplayDefaultBackgroundPatterns"
      class="app__background"
    >
      <div class="app__pattern app__pattern--one" />
      <div class="app__pattern app__pattern--two" />
    </div>
    <nav-header
      :headerInput="headerNav"
      :signalRStrip="signalRStripData"
      :routeList="routeList"
      :routeOn="subHeaders"
      @loadNav="navToLoad"
      @setIndicationData="setIndicationData"
      @event="callback"
      :indicationInfo="indicationData"
    ></nav-header>
    <error-popup
      v-on:showErrorPopup="showErrorPopup($event)"
      :errorResponse="errorResponse"
      v-on:noAction="noAction($event)"
    ></error-popup>
    <div
      class="app__container"
      :style="[isMobileView && { 'padding-top': paddingTop + 'px' }]"
    >
      <router-view
        :class="[
      'main-height',
      indicationData.showStrip || signalRStripData.stripShow
        ? 'mt-120'
        : 'mt-80',
      routeOn.subHeader && 'root-container',
      this.$route.path === '/planner' ? 'planner-component' : '',
      this.$route.path === '/account/programs' ? 'account-programs' : ''
    ]"
        id="rootContainer"
        :signalRStrip="signalRStripData"
        @loadNav="navToLoad"
        @loadNavs="navToLoad"
        @indicationStrip="visitProgramAdmin"
        @update_userDt="updateUser"
        @showSignalRStripIndicater="showSignalRStrip"
        @updateDataLayer="updateDataLayer"
        @setIndicationData="setIndicationData"
        v-on:showBlocker="showBlocker($event)"
        :userDetails="{
      id,
      firstName,
      lastName,
      profilePicture,
      email,
      accountID,
      userLabel,
      progName,
      programId,
      siteId,
      programStartDate,
      programEndDate,
      sitePanels,
      roleId,
      siteDetailData
    }"
        :roleId="roleId"
        v-on:noAction="noAction($event)"
        @updateSiteAtApp="updateSite($event)"
        v-on:updateDrillSiteName="updateDrillSiteName($event)"
        v-on:updateDrillProgramName="updateDrillProgramName"
      />
    </div>
    <footer-comp
      :routeOn="subHeaders"
      class="app__footer"
    />
    <portal-target name="modal" />
  </div>
</template>

<script>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import '@/assets/fonts/montserrat-font.css';

import Header from '@/ui-components/header/headerComponent.vue';
import FooterComponent from '@/commoncomponents/footercomponent/FooterComponent.vue';
import ErrorPopupComponent from '@/commoncomponents/errorpopupcomponent/ErrorPopUpComponent.vue';
import { writeData, clearAllData } from '@/utilities/idbUtility';
import { fetchLMSUrl } from './services/dashboard/dashboardService';
import plannerStore from '@/store/modules/plannerStore';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';
import programConfigureStore from '@/store/modules/programConfigure';
import programListStore from '@/store/modules/programList';
import {
  getUserById,
  getUserDetailsResponseObj,
  logoutUser
} from '@/services/userService/users-api';
import headerStore from '@/store/modules/headerStore';
import store from '@/store';
import { getInstance } from '@/auth';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { AnalyticsInjectionKey } from '@/analytics';

UIkit.use(Icons);
export default {
  name: 'App',
  components: {
    'nav-header': Header,
    'footer-comp': FooterComponent,
    'error-popup': ErrorPopupComponent
  },
  inject: {
    analyticsService: {
      from: AnalyticsInjectionKey,
      default: null,
    }
  },
  data() {
    return {
      isAuthTokenSet: false,
      isAppReady: false,
      colorNav: false,
      colorDashboard: false,
      colorHome: false,
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      profilePicture: [{ imageUrl: '', thumbnailUrl: '' }],
      userLabel: '',
      progName: '',
      colorScholar: false,
      colorNewScholar: false,
      accountID: 0,
      programId: 0,
      siteId: 0,
      roleId: 0,
      sitePanels: [],
      programStartDate: '',
      programEndDate: '',
      routeOn: { title: '', returnTo: '', subHeader: false },
      errorResponse: {},
      pointerEventNone: false,
      siteDetailData: {},
      showStrip: false,
      headerToUpdate: false,
      updateSiteName: false,
      headerNav: {},
      subHeaders: { title: '', returnTo: '', subHeader: false, bgColor: '' },
      indicationData: {
        showStrip: false,
        stripText: '',
        name: '',
        hover: false
      },
      routeList: [],
      signalRStripData: { component: '', stripShow: false, stripText: '' },
      isSignalRProcessing: true,
      dlObject: {
        AccountName: undefined,
        AccountId: 0,
        AccountType: undefined,
        UserRole: 0,
        UserId: 0,
        ProgramName: undefined,
        ProgramId: 0,
        SiteName: undefined,
        SiteId: 0
      },
      paddingTop: 0,
      isMobileView: false,
      globalModule: getModule(GlobalModule, store),
      userGuidingLoaded: false
    };
  },
  created() {
    this.isMobileView = APP_UTILITIES.isMobile();
    this.$eventHub.$on('showErrorPopup', this.showErrorPopup);
    this.$eventHub.$on(
      'showAttendanceUndoStripIndicater',
      this.showSignalRStrip
    );
    this.$eventHub.$on('sendInvitationStripIndicator', this.showSignalRStrip);
    this.$eventHub.$on('resetPasswordStripIndicator', this.showSignalRStrip);

    // init launch darkly
    const context = {
      kind: 'user',
      key: APP_UTILITIES.getUserID()
    };
    try {
      const client = LDClient.initialize(
        process.env.VUE_APP_LD_CLIENT_ID,
        context
      );
      APP_UTILITIES.setLDClient(client);
    }
    catch (error) {
      console.error('Error initializing LaunchDarkly:', error);
    }
  },
  updated() {
    this.isMobileView = APP_UTILITIES.isMobile();
    const h1 = document.getElementsByClassName('indication-strip');
    if (h1[0] != undefined && h1[0].offsetHeight) {
      this.paddingTop = h1[0].offsetHeight - 15;
    }
    this.checkBxDrillDown();
  },
  methods: {
    /**
     * It resets the role that was previously changed when sending the user to enrollment.
     */
    async handleRedirectBackFromEnrollment(userByIdResponse) {
      const { roleId } = APP_UTILITIES.coreids();
      if (![APP_CONST.ROLES.BX_SUPER_ADMIN, APP_CONST.ROLES.BX_ADMIN].includes(roleId)) {
        return;
      }
      /* istanbul ignore if */
      if (userByIdResponse && userByIdResponse.status === APP_CONST.RESPONSE_200) {
        const userData = userByIdResponse.data && userByIdResponse.data.userRoles;
        APP_UTILITIES.setCookie('user_role', JSON.stringify(userData), 1);
        document.cookie = `isFinalSubmit=true; expires=${new Date()}; path=/;`;
        document.cookie = `super_user_drilldown=; expires=${new Date()}; path=/;`;
        document.cookie = `super_user_drilldown_to_account=; expires=${new Date()}; path=/;`;
        document.cookie = `drilldown_key=; expires=${new Date()}; path=/;`;
        document.cookie = `siteId=;expires=${new Date()}; path=/;`;
        document.cookie = `siteName=; expires=${new Date()}; path=/;`;
      }
    },
    beforeDestroy() {
      if (UIkit.modal('#technical-error-msg')) {
        UIkit.modal('#technical-error-msg').$destroy(true);
      }
    },
    updateHeader(ev) {
      this.headerToUpdate = ev.updateHeader;
      this.validateToken();
    },
    showErrorPopup(error) {
      const supressGlobalErrorPopup = this.globalModule.supressGlobalErrorPopup;
      if (error && supressGlobalErrorPopup === false) {
        this.errorResponse = {
          name: error.name
        };
      }
    },
    updateDataLayer(data) {
      const dlData = { ...data };

      // Append additional values for GTM
      const user_role = JSON.parse(APP_UTILITIES.getCookie('user_role'))[0];
      dlData.UserId = user_role.userId
        ? user_role.userId
        : undefined;
      dlData.AccountId =
        user_role.accountId === 0
          ? APP_UTILITIES.getCookie('accountId')
          : user_role.accountId;
      dlData.ProgramId =
        user_role.programId === 0
          ? APP_UTILITIES.getCookie('programId')
          : user_role.programId;
      dlData.SiteId =
        user_role.siteId === 0
          ? APP_UTILITIES.getCookie('siteId')
          : user_role.siteId;

      // Console out GTM values
      console.log('==gtm values==');
      console.log(dlData);

      Object.keys(dlData).forEach((item, index) => {
        this.dlObject[item] = dlData[item];
      });
      if (window.dataLayer) {
        console.log('==dataLayer.push==');
        console.log(this.dlObject);
        window.dataLayer.push(this.dlObject);
      }
    },
    updateDrillSiteName(event) {
      this.indicationData.name = event;
    },

    updateDrillProgramName(name) {
      this.indicationData.name = name;
      this.indicationData.hover = name && name.length > 25
        ? true
        : false;
    },

    noAction(event) {
      this.pointerEventNone = event;
    },
    updateSite(event) {
      this.sitePanels.forEach(item => {
        if (item.siteId === event.siteId) {
          item.startDate = event.startDate;
          item.endDate = event.endDate;
        }
      });
    },
    getCookie: name => {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    },
    setCookie(name, value, days) {
      let expires = '';
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + (value || '') + expires + '; path=/';
    },
    checkForRole(data) {
      const matchedRoute = (data && data.matched) || null;
      const { roleId } = APP_UTILITIES.coreids();
      if (matchedRoute) {
        for (const route of matchedRoute) {
          if (
            route.props &&
            route.props.default &&
            route.props.default.path === data.path
          ) {
            const roleExists = [4, 5, 6, 7].includes(roleId);
            this.subHeaders.title = route.props.default.subHeaderTitle || '';
            this.subHeaders.returnTo =
              route.path == '/home' && roleExists
                ? ''
                : route.props.default.returnTo || '';
            this.subHeaders.subHeader =
              route.path == '/home' && roleExists
                ? false
                : route.props.default.subHeader || false;
            this.subHeaders.bgColor =
              route.path == '/home' && roleExists
                ? 'blue'
                : route.props.default.bgColor || '';
            break;
          }
          else {
            this.subHeaders.title = '';
            this.subHeaders.returnTo = '';
            this.subHeaders.subHeader = false;
            this.subHeaders.bgColor =
              this.$route.path === '/partnerhome'
                ? 'blue'
                : '';
          }
        }
      }
      this.headerNav.background = this.subHeaders.bgColor;
    },
    storeAuthDataIndxdb(id, authToken) {
      clearAllData(APP_CONST.AUTH_DATA).then(function () {
        writeData(APP_CONST.AUTH_DATA, {
          id: id,
          authToken: authToken
        }).then(() => { });
      });
    },
    getProgramDt(programId, siteId) {
      programConfigureStore
        .getProgramConfigObjProgramPage(programId)
        .then(progDt => {
          if (progDt.status === APP_CONST.RESPONSE_200) {
            this.getSiteDetails(siteId, progDt.data.sitePanels);
            this.setCookie(
              'isProgramConfigured',
              JSON.stringify(progDt.data.isFinalSubmit),
              1
            );
            this.progName = progDt.data.programDto.name;
            this.sitePanels = progDt.data.sitePanels;
            this.programStartDate = progDt.data.programDto.startDate;
            plannerStore.updateSitesData(progDt.data.sitePanels);
            this.programEndDate = progDt.data.programDto.endDate;
            this.setCookie('progName', this.progName, 1);
            this.setCookie('programId', programId, 1);
            this.isAppReady = true;
            this.setIndicationData();
          }
          else if (!progDt.data && !progDt.status) {
            this.setCookie('isProgramConfigured', JSON.stringify(true), 1);
            this.isAppReady = true;
          }
        });
    },
    getSiteDetails(siteId, sitePannels) {
      //this.startSocket();
      sitePannels.forEach(item => {
        if (siteId === item.siteId) {
          this.siteDetailData = item;
          APP_UTILITIES.setCookie('siteId', JSON.stringify(item.siteId), 1);
          APP_UTILITIES.setCookie(
            'siteAutoEnroll',
            JSON.stringify(item.autoEnroll),
            1
          );
          APP_UTILITIES.setCookie('siteName', item.siteName, 1);
        }
      });
    },
    validateUserById(userId, authToken) {
      const parsedId = parseInt(userId);
      if (parsedId > 0) {
        getUserById(parsedId).then(async response => {

          if (response && response.status === 200) {
            await this.handleRedirectBackFromEnrollment(response);
            const res = response.data;
            this.isAuthTokenSet = true;
            this.setCookie('user_id', userId, 1);
            APP_UTILITIES.setCookie(
              'all_programs_associated_to_program_admin',
              JSON.stringify(res.userRoles),
              1
            );
            const drill_available = this.getCookie('drilldown_key');
            if (
              this.getCookie('super_user_drilldown') ||
              (drill_available && JSON.parse(drill_available).actor > 0)
            ) {
              const userData = JSON.parse(APP_UTILITIES.getCookie('user_role'));
              res.userRoles = userData;
              this.showStrip = true;
            }
            else if (this.getCookie('account_admin_drilldown')) {
              const userData = JSON.parse(APP_UTILITIES.getCookie('user_role'));
              res.userRoles = userData;
              this.showStrip = false;
            }
            else {
              // this.setCookie("user_role", JSON.stringify(res.userRoles));
              this.showStrip = false;
            }
            let index = 0;
            this.firstName = res.firstName;
            this.lastName = res.lastName;
            this.id = res.id;
            this.email = res.email;
            this.accountID = res.userRoles[0].accountId;
            res.userRoles.forEach((item, id) => {
              if (item.hasOwnProperty('programId')) {
                index = id;
                this.programId = item.programId;
              }
              if (item.hasOwnProperty('siteId')) {
                this.siteId = item.siteId;
              }
              if (item.hasOwnProperty('roleId')) {
                this.roleId = item.roleId;
                this.checkForRoute();
              }
            });
            this.userLabel = `${APP_CONST.USER_TYPE_LABEL[res.userRoles[index].roleId - 1]
            }`;
            this.storeAuthDataIndxdb(userId, authToken);
            this.headerToUpdate = !this.headerToUpdate;
            this.navToLoad();
            this.setIndicationData();
          }
          else if (response.status === undefined) {
            this.$router.go();
          }
          else {
            this.isAuthTokenSet = false;
            window.location.href =
              process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL;
          }
        });
      }
      else {
        window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL;
      }
    },
    checkForRoute() {
      const drill_available = this.getCookie('drilldown_key');
      const { roleId } = APP_UTILITIES.coreids();
      if (!this.routeOn.subHeader) {
        if (
          this.$route.path === '/home' &&
          roleId > APP_CONST.ZERO &&
          roleId < APP_CONST.THREE
        ) {
          this.routeOn.subHeader = true;
        }
      }
      else if (
        this.routeOn.subHeader &&
        this.$route.path === '/home' &&
        (APP_UTILITIES.getCookie('super_user_drilldown') ||
          (drill_available && JSON.parse(drill_available).actor > 0))
      ) {
        this.routeOn.title = '';
        this.routeOn.returnTo = '';
        this.routeOn.subHeader = false;
      }
    },
    validateToken() {
      if (APP_UTILITIES.checkBrowserStatus()) {
        const checkToken = this.getCookie('auth_token');
        const chkUserId = this.getCookie('user_id');
        if (checkToken !== undefined) {
          this.validateUserById(chkUserId, checkToken);
        }
        else {
          this.isAuthTokenSet = false;
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL;
        }
      }
      else {
        this.isAuthTokenSet = true;
      }
    },
    getProramDetails() {
      const { roleId, programId, siteId } = APP_UTILITIES.coreids();
      this.roleId = roleId;
      this.programId = programId;
      this.siteId = siteId;
      if (roleId > APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN) {
        this.getProgramDt(this.programId, this.siteId);
      }
      else {
        this.setCookie('isProgramConfigured', JSON.stringify(true), 1);
        this.isAppReady = true;
      }
    },
    async navToLoad(ev) {
      this.getProramDetails();
      let navbar = [];
      navbar = await APP_UTILITIES.roleBasedNav();
      const profileNav = navbar.navView.findIndex(el => el.profile === true);
      if (profileNav >= 0) {
        navbar.navView[profileNav].title = this.firstName;
      }
      this.headerNav = navbar;
      this.checkForRole(this.$route);
    },
    siteVisit(data) {
      plannerStore.emptyEventList([]);
      APP_UTILITIES.setCookie(
        'drilldown_key',
        JSON.stringify({ actor: 5, drill: 6 }),
        1
      );
      let {
        id,
        programId,
        siteId,
        roleId,
        accountId
      } = APP_UTILITIES.coreids();
      if (data.siteId > 0) {
        this.getProgramDt(programId, siteId);
        siteId = data.siteId;
        this.siteId = data.siteId;
        APP_UTILITIES.setCookie('siteId', JSON.stringify(data.siteId), 1);
        APP_UTILITIES.setCookie(
          'siteAutoEnroll',
          JSON.stringify(data.autoEnroll),
          1
        );
        APP_UTILITIES.setCookie('siteName', JSON.stringify(data.siteName), 1);
        roleId = APP_CONST.ROLE_TYPE_SESSION_ADMIN;
        APP_UTILITIES.setCookie(
          'user_role',
          JSON.stringify([{ id, programId, siteId, roleId, accountId }]),
          1
        );
        //this.startSocket()
      }

      if (this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home' });
      }
      this.setIndicationData();
    },
    setIndicationData() {
      const { roleId } = APP_UTILITIES.coreids();
      let showStrip = false,
        stripText = '',
        program = '',
        site = '',
        name = '',
        hover = false;
      if (APP_UTILITIES.getCookie('super_user_drilldown')) {
        showStrip = true;
        programListStore.getAppSettings();
        if (roleId == APP_CONST.ACCOUNT_ADMIN_ROLE_ID) {
          stripText = 'You’re viewing the account: “';
          name = APP_UTILITIES.getCookie('accountName');
          hover = name && name.length > 25
            ? true
            : false;
        }
        else {
          stripText =
            roleId >= 6
              ? 'You’re viewing the site/session: “'
              : 'You’re viewing the program: “';
          program = APP_UTILITIES.getCookie('progName');
          this.progName = APP_UTILITIES.getCookie('progName');
          site = APP_UTILITIES.getCookie('siteName');
          name = roleId >= 6
            ? site
            : program;
          hover = name && name.length > 25
            ? true
            : false;
        }
        this.roleId = roleId;
      }
      this.indicationData = {
        showStrip,
        stripText,
        name,
        hover,
        callback: 'stepToOriginalRole'
      };
    },
    async stepToOriginalRole() {
      plannerStore.emptyEventList([]);
      const userId = this.getCookie('user_id');
      const response = await getUserById(userId);
      if (response && response.status === APP_CONST.RESPONSE_200) {
        const userData = response.data && response.data.userRoles;
        const drill_available = APP_UTILITIES.getCookie('drilldown_key');
        const super_user_drilldown = APP_UTILITIES.getCookie(
          'super_user_drilldown'
        );
        APP_UTILITIES.setCookie('user_role', JSON.stringify(userData), 1);
        APP_UTILITIES.setCookie('super_user_drilldown_to_account', '', 1);
        this.roleId = userData[0].roleId;
        document.cookie = `isProgramConfigured=true; expires=${new Date()}; path=/;`;
        document.cookie = `super_user_drilldown=; expires=${new Date()}; path=/;`;
        document.cookie = `drilldown_key=; expires=${new Date()}; path=/;`;
        document.cookie = `siteId=;expires=${new Date()}; path=/;`;
        document.cookie = `siteName=; expires=${new Date()}; path=/;`;
        if (drill_available && !super_user_drilldown) {
          if (userData[0].roleId == APP_CONST.ACCOUNT_ADMIN_ROLE_ID) {
            this.navigate();
          }
          else {
            this.navigate();
            if (this.$route.name !== 'Home') {
              this.$router.push({ name: 'Home' }).then(() => {
                this.navToLoad();
              });
            }
          }
        }
        else {
          if (userData[0].roleId == APP_CONST.ACCOUNT_ADMIN_ROLE_ID) {
            this.navigate();
          }
          if (this.$route.path == '/home') {
            this.navToLoad();
            location.reload();
          }
          else {
            this.navToLoad();
            this.$router.push('/home');
          }
        }
        this.indicationData = {
          showStrip: false,
          stripText: '',
          name: '',
          hover: false,
          callback: 'stepToOriginalRole'
        };
        this.signalRStripData = {
          component: '',
          stripShow: false,
          stripText: ''
        };
      }
    },

    navigate() {
      this.$router.push('/home').catch(failure => { });
      this.isAppReady = false;
      setTimeout(() => {
        this.isAppReady = true;
      }, 100);
    },
    navigateUrl(data) {
      const newWindow = window.open();
      if (data.id) {
        fetchLMSUrl(data.id).then(res => {
          if (res.status === 200) {
            newWindow.location = res.data;
          }
        });
      }
    },
    logout() {
      UIkit.notification.closeAll();
      logoutUser().then(() => {
        const userName = APP_UTILITIES.getCookie('username');
        APP_UTILITIES.removeCookies();
        localStorage.clear();
        if (userName) {
          APP_UTILITIES.setCookie(
            APP_CONST.USERNAME,
            userName,
            APP_CONST.ONE
          );
        }
      });
    },
    async getUserDetails() {
      const { id } = APP_UTILITIES.coreids();
      const promise_user = getUserDetailsResponseObj(id);
      const result_user = await promise_user;
      const userDetails = result_user.data;
      if (result_user.status == APP_CONST.RESPONSE_200) {
        const activeRoles = userDetails.userRoles.filter(el => {
          return el.status === APP_CONST.STATUS_ACTIVE;
        });
        const roles = activeRoles.map(el => el.roleId);
        const highestRole = Math.min(...roles);
        const role = userDetails.userRoles.find(
          el => el.roleId === highestRole
        );
        role.roleId = highestRole <= 4
          ? highestRole
          : 0;
        const userRole =
          highestRole > 3
            ? JSON.stringify([role])
            : JSON.stringify(userDetails.userRoles);
        APP_UTILITIES.setCookie('user_role', userRole, 1);
        if (this.$route.path != '/partnerhome') {
          this.$router.replace('/partnerhome');
        }
        this.navToLoad();
      }
    },
    showSignalRStrip(obj) {
      this.signalRStripData = obj;
    },
    callback(el) {
      this[el.callEvent](el.data);
    },
    visitProgramAdmin() {
      this.checkForRoute();
      this.navToLoad();
    },
    updateUser(ev) {
      if (ev.programName) {
        this.progName = ev.programName;
      }
    },
    checkBxDrillDown() {
      const bxDrillToProgram = Boolean(
        APP_UTILITIES.getCookie('super_user_drilldown')
      );
      const bxDrillToAccount = Boolean(
        APP_UTILITIES.getCookie('super_user_drilldown_to_account')
      );
      headerStore.mutateBxState(bxDrillToProgram || bxDrillToAccount);
    },
    checkUserGuiding() {
      if (window.userGuiding) {
        this.userGuidingLoaded = true;
      }
      else {
        const interval = setInterval(() => {
          if (window.userGuiding) {
            this.userGuidingLoaded = true;
            clearInterval(interval);
          }
        }, 100);
      }
    },
    identifyUserGuiding() {
      window.userGuiding.identify(`${APP_UTILITIES.getUserID()}(${document.location.hostname.split('.')[0]})`);
    }
  },
  watch: {
    $route(data) {
      this.checkForRole(data);
      const matchedRoute = data.matched;
      const { roleId } = APP_UTILITIES.coreids();
      for (const route of matchedRoute) {
        if (
          route.props &&
          route.props.default &&
          route.props.default.path === data.path
        ) {
          this.routeOn.title =
            route.path == '/home' && (roleId == 4 || roleId == 5)
              ? ''
              : route.props.default.subHeaderTitle || '';
          this.routeOn.returnTo =
            route.path == '/home' && (roleId == 4 || roleId == 5)
              ? ''
              : route.props.default.returnTo || '';
          this.routeOn.subHeader =
            route.path == '/home' && (roleId == 4 || roleId == 5)
              ? false
              : route.props.default.subHeader || false;
          break;
        }
        else {
          this.routeOn.title = '';
          this.routeOn.returnTo = '';
          this.routeOn.subHeader = false;
        }
      }
      this.checkForRoute();
    },
    userGuidingLoaded(newVal) {
      if (newVal) {
        this.identifyUserGuiding();
      }
    }
  },

  async beforeMount() {
    const authService = getInstance();
    if (authService) {
      // if auth client is undefined we initialize it for its usage
      if (!(await authService.getAuthClient())) {
        await authService.iniAuth();
      }
      const silent = await authService.getTokenSilently();
      APP_UTILITIES.setUserCookies(silent, false);
      this.analyticsService.identify(APP_UTILITIES.getUserID().toString(), {
        name: APP_UTILITIES.getUserName(),
        role: APP_UTILITIES.getUserRole(APP_UTILITIES.getUserHighestRole()),
        organization_id: APP_UTILITIES.getAccountId(),
        organization_name: APP_UTILITIES.getAccountName()
      });
      this.analyticsService.group(APP_UTILITIES.getAccountId().toString(), {
        name: APP_UTILITIES.getAccountName()
      });
    }

    this.validateToken();
    this.routeList = APP_CONST.IGNORE_NAV_ON_ROUTELIST;
  },
  mounted() {
    this.checkBxDrillDown();
    this.checkUserGuiding();
  },
  computed: {
    shouldDisplayDefaultBackgroundPatterns() {
      return (
        !this.$route.meta || !this.$route.meta.excludeDefaultBackgroundPatterns
      );
    }
  }
};
</script>
