import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import LandscapeMode from '@/popupcomponents/landscapemodepopup/LandscapeMode.vue';

@Component({
  components: {
    'landscape-mode': LandscapeMode
  }
})
export default class FooterComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public navItems:any = false;
    public unnestedClick:any = 0;
    public roleType:number = 0;
    public offLineConnectionMessage:string='';
    public mobilefooterFixed:boolean=false;
    public deviceOffLineMessageCheck:boolean=false;
    public checkMobileWidth:number=992;
    public scrollHeightY:number=0;
    public checkMobileDevice:boolean=false;
    public offlineMessage:string='OFFLINE_MESSAGE';
    public navigatornLine:boolean=!navigator.onLine;
    public deviceFormatCheck:string=navigator.userAgent;

    @Prop({default: {title: '', returnTo: '', subHeader: false, bgColor: ''}})
    routeOn!: {title: string; returnTo: string; subHeader: boolean; bgColor: string};


    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    public goToHomePage(typeHome:string) {
      window.location.href = process.env.VUE_APP_BASE_ADMIN_REDIRECT_URL+typeHome;
    }

    goToScholarPage(route:string,unnestedClick:number,index:any = '') {
      /* istanbul ignore else */
      if (route=='') {
        return ;
      }
      if (typeof(index) =='number') {
        this.navItems[index].openStatus = false;
      }
      this.unnestedClick = unnestedClick;
      this.$router.push(route&&route.length>0
        ?route
        :APP_CONST.SCHOLAR);
    }

    openUrl() {
      window.open(APP_CONST.PRIVACY_POLICY, '_blank');
    }
    mounted() {
      document.addEventListener('scroll',this.handleFooterScroll);
      this.offLineConnectionMessageCheck();
    }

    handleFooterScroll() {
      const widthScroll=window.screen.width;
      const windowscrollY=window.scrollY;
      /* istanbul ignore else */
      if (widthScroll<= this.checkMobileWidth) {
        this.scrollHeightY = document.documentElement.scrollHeight - document.documentElement.clientHeight-APP_CONST.MOBLIE_HEIGHT_SIXNINE;
      }
      else {
        this.scrollHeightY = document.documentElement.scrollHeight - document.documentElement.clientHeight-APP_CONST.MOBLIE_HEIGHT_TWOFOURTYTWO;
      }
      /* istanbul ignore else */
      if (windowscrollY >= this.scrollHeightY) {
        this.mobilefooterFixed = true;
      }
      else {
        this.mobilefooterFixed = false;
      }
    }

    offLineConnectionMessageCheck() {
      this.$nextTick(function () {
        window.setInterval(() => {
          this.callBackoffLineConnectionMessageCheck();
        },1000);
      });

    }

    callBackoffLineConnectionMessageCheck() {
      this.checkMobileDevice=((this.deviceFormatCheck.indexOf('iPhone')!=-1) || (this.deviceFormatCheck.indexOf('Android')!=-1)  || (this.deviceFormatCheck.indexOf('iPad')!=-1));
      this.navigatornLine=!navigator.onLine;
      if (this.navigatornLine && this.checkMobileDevice) {
        this.deviceOffLineMessageCheck =true;
        this.offLineConnectionMessage = this.getScreenText(this.offlineMessage);
      }
      else {
        this.deviceOffLineMessageCheck =false;
        this.offLineConnectionMessage='';
      }
    }

}