import APP_CONST from '@/constants/AppConst';
import axios from 'axios';

export async function mapAccountCmsLicenses(cmsLicenses: { accountId: number; cmsSubscriptionId: string; grades?: string[]; status: number }[]) {
  if (cmsLicenses.length === 0) {
    return [];
  }

  const subscriptions: { sys: { id: string }; subscriptionCategory: Array<string>; subscriptionTitle: string }[] | undefined = await getAllSubscriptionCollections();

  if (!subscriptions || subscriptions.length === 0) {
    return [];
  }

  const subscriptionsMapping = subscriptions.reduce((acc, curr) => {
    acc[curr.sys.id] = curr.subscriptionTitle;
    return acc;
  }, {} as Record<string, string>);

  const cmsLicensesGradesMapping = cmsLicenses.reduce((acc, curr) => {
    const name = subscriptionsMapping[curr.cmsSubscriptionId];
    if (name) {
      acc[name] = curr.grades || [];
    }
    return acc;
  }, {} as Record<string, string[]>);

  return Object.entries(cmsLicensesGradesMapping).map(([subscriptionName, subscriptionGrades]) => {
    return {
      subscriptionName,
      subscriptionGrades
    };
  });
}

export const getSubscriptionCategories = async (subscriptionIds: Array<string>, byTitle: boolean = false) => {
  if (subscriptionIds === undefined || subscriptionIds.length === 0) {
    return [];
  }

  const allSubs = await getAllSubscriptionCollections();

  if (allSubs === undefined || allSubs.length === 0) {
    return [];
  }

  const allSubCategories: Array<string> = [];
  allSubs.forEach((sub: { sys: { id: string }; subscriptionCategory: Array<string>; subscriptionTitle: string }) => {
    if (subscriptionIds.includes(sub.sys.id)) {
      if (byTitle) {
        allSubCategories.push(sub.subscriptionTitle);
      }
      else {
        allSubCategories.push(...sub.subscriptionCategory);
      }
    }
  });
  const unique = new Set(allSubCategories.flat());
  const uniqueArray = Array.from(unique);
  return uniqueArray;
};

export const getAllSubscriptionCollections = async () => {
  return axios({
    url: `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`,
    headers: {
      Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}`
    },
    method: 'post',
    data: {
      query: `
      {
        subscriptionCollection {
          items {
              sys {
                  id
              }
              subscriptionTitle
              subscriptionCategory
          }
        }
      }
      `
    }
  })
    .then(result => {
      const data = result.data.data;
      const categories = data.subscriptionCollection.items;
      // Re oder categories according to hard coded list
      categories.sort((a: any, b: any) => {
        const aIndex = APP_CONST.CONTENTFUL_SUBSCRIPTION_ORDER.indexOf(a.subscriptionTitle);
        const bIndex = APP_CONST.CONTENTFUL_SUBSCRIPTION_ORDER.indexOf(b.subscriptionTitle);
        if (aIndex < bIndex) {
          return -1;
        }
        if (aIndex > bIndex) {
          return 1;
        }
        return 0;
      });
      return categories;
    })
    .catch(err => {
      console.error('Failed to Fetch Subscription List', err);
      //ToDo: handle failed fetch here.
    });

};
