import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Route } from 'vue-router';
import flagGuard from './guard';
import { getaccountbyid } from '@/services/userService/users-api';
import { AppRouteConfig } from './index';
import { analyticsEventNames } from '@/analytics';

function lazyLoad(view: any) {
  return () => import(`@/${view}.vue`);
}
const currentLogedInRole = () => {
  const { roleId } = APP_UTILITIES.coreids();
  return roleId;
};

const routes: AppRouteConfig[] = [
  {
    path: APP_CONST.APP_ROUTES.ROOT.path,
    name: APP_CONST.APP_ROUTES.ROOT.name,
    component: lazyLoad('components/rootcomponent/RootComponent'),
    props:
      currentLogedInRole() > 0 && currentLogedInRole() < 3
        ? {
          subHeaderTitle: 'Partner Accounts',
          returnTo: '',
          path: APP_CONST.APP_ROUTES.ROOT.path,
          subHeader: true,
          bgColor: 'blue-theme'
        }
        : {
          subHeaderTitle: 'HOME',
          returnTo: '',
          path: APP_CONST.APP_ROUTES.ROOT.path,
          subHeader: false,
          bgColor: 'blue'
        },
    meta: { name: 'BellXcel', gtm: 'Home' }
  },
  {
    path: APP_CONST.APP_ROUTES.ACCOUNT.path,
    name: APP_CONST.APP_ROUTES.ACCOUNT.name,
    component: lazyLoad(
      'components/accountDetailsComponent/AccountDetailsComponent'
    ),
    props: {
      subHeaderTitle: 'ACCOUNT',
      returnTo: '/home',
      path: APP_CONST.APP_ROUTES.ACCOUNT.path,
      subHeader: true,
      bgColor: 'blue-theme'
    },
    children: [
      {
        path: APP_CONST.APP_ROUTES.ACCOUNT.path,
        redirect: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS.path
      },
      {
        path: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS.path,
        name: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS.name,
        component: lazyLoad(
          'components/accountDetailsComponent/accountDataTabs/programs/Programs'
        ),
        props: {
          subHeaderTitle: 'ACCOUNT',
          returnTo: '',
          path: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS.path,
          subHeader: true,
          bgColor: 'blue-theme'
        },
        meta: {
          name: 'Account Programs',
          gtm: 'Account Programs',
          reuse: false
        }
      },
      {
        path: APP_CONST.APP_ROUTES.ACCOUNT_TEAM.path,
        name: APP_CONST.APP_ROUTES.ACCOUNT_TEAM.name,
        component: lazyLoad(
          'components/accountDetailsComponent/accountDataTabs/users/Users'
        ),
        props: {
          subHeaderTitle: 'ACCOUNT',
          returnTo: '',
          path: APP_CONST.APP_ROUTES.ACCOUNT_TEAM.path,
          subHeader: true,
          bgColor: 'blue-theme'
        },
        meta: { name: 'Account Users', gtm: 'Account Users', reuse: false }
      },
      {
        path: APP_CONST.APP_ROUTES.ACCOUNT_SITES.path,
        name: APP_CONST.APP_ROUTES.ACCOUNT_SITES.name,
        component: lazyLoad(
          'components/accountDetailsComponent/accountDataTabs/sites/Sites'
        ),
        props: {
          subHeaderTitle: 'ACCOUNT',
          returnTo: '',
          path: APP_CONST.APP_ROUTES.ACCOUNT_SITES.path,
          subHeader: true,
          bgColor: 'blue-theme'
        },
        meta: { name: 'Account Sites', gtm: 'Account Sites', reuse: false }
      },
      {
        path: APP_CONST.APP_ROUTES.ACCOUNT_PARTICIPANTS.path,
        name: APP_CONST.APP_ROUTES.ACCOUNT_PARTICIPANTS.name,
        component: lazyLoad(
          'components/accountDetailsComponent/accountDataTabs/participants/Participants'
        ),
        props: {
          subHeaderTitle: 'ACCOUNT',
          returnTo: '',
          path: APP_CONST.APP_ROUTES.ACCOUNT_PARTICIPANTS.path,
          subHeader: true,
          bgColor: 'blue-theme'
        },
        meta: {
          name: 'Account Participants',
          gtm: 'Account Participants',
          reuse: false,
          analytics: {
            eventName: analyticsEventNames.PARTICIPANT_TABLE_VIEWED
          }
        }
      },
      {
        path: APP_CONST.APP_ROUTES.ACCOUNT_CONTACTS.path,
        name: APP_CONST.APP_ROUTES.ACCOUNT_CONTACTS.name,
        component: lazyLoad(
          'components/accountDetailsComponent/accountDataTabs/contacts/Contacts'
        ),
        props: {
          subHeaderTitle: 'ACCOUNT',
          returnTo: '',
          path: APP_CONST.APP_ROUTES.ACCOUNT_CONTACTS.path,
          subHeader: true,
          bgColor: 'blue-theme'
        },
        meta: {
          name: 'Account Contacts',
          gtm: 'Account Contacts',
          reuse: false,
          analytics: {
            eventName: analyticsEventNames.FAMILY_CONTACTS_TABLE_VIEWED
          }
        }
      },
      {
        path: APP_CONST.APP_ROUTES.ACCOUNT_PAYMENTS.path,
        name: APP_CONST.APP_ROUTES.ACCOUNT_PAYMENTS.name,
        component: lazyLoad(
          'components/accountDetailsComponent/accountDataTabs/payments/Payments'
        ),
        props: {
          subHeaderTitle: 'ACCOUNT',
          returnTo: '',
          path: APP_CONST.APP_ROUTES.ACCOUNT_PAYMENTS.path,
          subHeader: true,
          bgColor: 'blue-theme'
        },
        meta: {
          name: 'Account Payments',
          gtm: 'Account Payments',
          reuse: false,
          analytics: {
            eventName: analyticsEventNames.PAYMENT_SETTINGS_VIEWED
          }
        },
        beforeEnter: (_to: Route, _from: Route, next: () => void) => {
          const allowedRoles = [
            APP_CONST.ROLES.BX_SUPER_ADMIN,
            APP_CONST.ROLES.ACCOUNT_ADMIN
          ];
          const highestRole = APP_UTILITIES.getUserHighestRole();
          const isAllowed = allowedRoles.includes(highestRole);
          if (!isAllowed) {
            return false;
          }
          next();
        }
      }
    ]
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_COURSE_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_COURSE_LANDING.name,
    component: lazyLoad('components/courseLandingPage/CourseLandingPage'),
    props: {
      subHeaderTitle: 'Learning Resources - Course',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_COURSE_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      courseIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_COURSE_LANDING_PAGE_VIEWED
      },
      excludeDefaultBackgroundPatterns: true
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_ROUTINE_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_ROUTINE_LANDING.name,
    component: lazyLoad('components/cms/routineLandingPage/RoutineLandingPage'),
    props: {
      subHeaderTitle: 'Learning Resources - Routine',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_ROUTINE_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      routineIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_ROUTINE_LANDING_PAGE_VIEWED
      },
      excludeDefaultBackgroundPatterns: true
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_STRATEGY_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_STRATEGY_LANDING.name,
    component: lazyLoad(
      'components/cms/strategyLandingPage/StrategyLandingPage'
    ),
    props: {
      subHeaderTitle: 'Learning Resources - Strategy',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_STRATEGY_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      strategyIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_STRATEGY_LANDING_PAGE_VIEWED
      },
      excludeDefaultBackgroundPatterns: true
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LANDING.name,
    component: lazyLoad(
      'components/cms/mediaResourceLandingPage/MediaResourceLandingPage'
    ),
    props: {
      subHeaderTitle: 'Learning Resources - Resource',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      resourceIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_RESOURCE_LANDING_PAGE_VIEWED
      },
      excludeDefaultBackgroundPatterns: true
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LONG_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LONG_LANDING.name,
    component: lazyLoad(
      'components/cms/mediaResourceLongLandingPage/MediaResourceLongLandingPage'
    ),
    props: {
      subHeaderTitle: 'Learning Resources - Resource Long',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LONG_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      resourceIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_RESOURCE_LANDING_PAGE_VIEWED
      }
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_VIDEO_RESOURCE_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_VIDEO_RESOURCE_LANDING.name,
    component: lazyLoad('components/videoResource/VideoResourcePage'),
    props: {
      subHeaderTitle: 'Learning Resources - Video',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_VIDEO_RESOURCE_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      courseIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_RESOURCE_LANDING_PAGE_VIEWED
      },
      excludeDefaultBackgroundPatterns: true
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_LEARN_ABOUT_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_LEARN_ABOUT_LANDING.name,
    component: lazyLoad(
      'components/cms/learnAboutLandingPage/LearnAboutLandingPage'
    ),
    props: {
      subHeaderTitle: 'Learn About',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_LEARN_ABOUT_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      contentIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_LEARN_ABOUT_LANDING_PAGE_VIEWED
      }
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_CONTENT_PREVIEW_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_CONTENT_PREVIEW_LANDING.name,
    component: lazyLoad(
      'components/cms/contentPreviewLandingPage/ContentPreviewLandingPage'
    ),
    props: {
      subHeaderTitle: 'Content Preview',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_CONTENT_PREVIEW_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      contentIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_CONTENT_PREVIEW_LANDING_PAGE_VIEWED
      }
    }
  },
  {
    path: APP_CONST.APP_ROUTES.MY_COLLECTIONS.path,
    name: APP_CONST.APP_ROUTES.MY_COLLECTIONS.name,
    component: lazyLoad(
      'components/cms/collections/MyCollectionsPage'
    ),
    props: {
      subHeaderTitle: `${APP_CONST.APP_ROUTES.MY_COLLECTIONS.name}`,
      returnTo: '',
      path: APP_CONST.APP_ROUTES.MY_COLLECTIONS.path,
      subHeader: false,
      bgColor: 'blue',
    },
    meta: {
      excludeDefaultBackgroundPatterns: true
    },
  },
  {
    path: APP_CONST.APP_ROUTES.SINGLE_COLLECTION.path,
    name: APP_CONST.APP_ROUTES.SINGLE_COLLECTION.name,
    component: lazyLoad('components/cms/collections/SingleCollectionPage'),
    props: ({ params: { collectionId = '' } }) => (
      {
        collectionId: Number.parseInt(collectionId, 10),
        subHeaderTitle: `${APP_CONST.APP_ROUTES.SINGLE_COLLECTION.name}`,
        returnTo: '',
        path: APP_CONST.APP_ROUTES.SINGLE_COLLECTION.path,
        subHeader: false,
        bgColor: 'blue',
      }
    ),
    meta: {
      excludeDefaultBackgroundPatterns: true
    },
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_VIEW_PDF_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_VIEW_PDF_LANDING.name,
    component: lazyLoad(
      'components/cms/mediaResourceLandingPage/viewPdfResource/ViewPdfResource'
    ),
    props: {
      subHeaderTitle: 'Learning Resources - Resource',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_VIEW_PDF_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      resourceIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_RESOURCE_LANDING_PAGE_VIEWED
      },
      excludeDefaultBackgroundPatterns: true
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_ACTIVITY_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_ACTIVITY_LANDING.name,
    component: lazyLoad(
      'components/cms/activityLandingPage/ActivityLandingPage'
    ),
    props: {
      subHeaderTitle: 'Learning Resources - Activity',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_ACTIVITY_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      resourceIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_ACTIVITY_LANDING_PAGE_VIEWED
      },
      excludeDefaultBackgroundPatterns: true
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_ACTIVITY_FITNESS_LANDING.path,
    name: APP_CONST.APP_ROUTES.CMS_ACTIVITY_FITNESS_LANDING.name,
    component: lazyLoad(
      'components/cms/activityFitnessLandingPage/ActivityFitnessLandingPage'
    ),
    props: {
      subHeaderTitle: 'Learning Resources - Activity Fitness',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_ACTIVITY_FITNESS_LANDING.path,
      subHeader: false,
      bgColor: 'blue',
      resourceIdQuery: (route: Route) => route.query.id
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_ACTIVITY_LANDING_PAGE_VIEWED
      },
      excludeDefaultBackgroundPatterns: true
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_CATALOG.path,
    name: APP_CONST.APP_ROUTES.CMS_CATALOG.name,
    component: lazyLoad('components/cms/catalogPage/CatalogPage'),
    props: (route) => ({
      subHeaderTitle: 'Learning Resources - Catalog',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_CATALOG.path,
      subHeader: false,
      bgColor: 'blue',
      resourceIdQuery: route.query.id,
      showCollectionsFlyoutQuery: route.query.showCollectionsFlyout === 'true'
    }),
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_LIBRARY_PAGE_VIEWED
      },
      excludeDefaultBackgroundPatterns: true
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS_MY_CONTENT.path,
    name: APP_CONST.APP_ROUTES.CMS_MY_CONTENT.name,
    component: lazyLoad('components/cms/dashboard/DashboardPage'),
    props: {
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS_MY_CONTENT.path,
      subHeader: false,
      bgColor: 'blue'
    },
    meta: {
      analytics: {
        eventName: analyticsEventNames.LEARNING_LAUNCHPAD_PAGE_VIEWED
      }
    }
  },
  {
    path: APP_CONST.APP_ROUTES.CMS.path,
    name: APP_CONST.APP_ROUTES.CMS.name,
    component: lazyLoad(
      'components/learningResourcesHome/LearningResourcesHome'
    ),
    props: {
      subHeaderTitle: 'Learning Resources',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.CMS.path,
      subHeader: true,
      bgColor: 'blue-theme'
    },
    meta: {
      excludeDefaultBackgroundPatterns: true
    },
    children: []
  },
  {
    path: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS_SESSIONS.path,
    name: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS_SESSIONS.name,
    component: lazyLoad(
      'components/accountDetailsComponent/accountDataTabs/programs/sessions/Sessions'
    ),
    props: {
      subHeaderTitle: 'SESSION',
      returnTo: '',
      path: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS_SESSIONS.path,
      subHeader: true,
      bgColor: 'blue-theme'
    },
    meta: { name: 'Program Sessions', gtm: 'Program Sessions', reuse: false }
  },
  {
    path: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAM_SESSION_CONFIGURATION.path,
    name: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAM_SESSION_CONFIGURATION.name,
    component: lazyLoad(
      'components/accountDetailsComponent/accountDataTabs/programs/sessions/configuration/SessionConfiguration'
    ),
    props: {
      returnTo: '',
      path: APP_CONST.APP_ROUTES.ACCOUNT_PROGRAM_SESSION_CONFIGURATION.path,
      bgColor: 'blue-theme'
    },
    meta: { name: 'Program Session Configuration', gtm: 'Program Session Configuration', reuse: false }
  },
  {
    path: '/account/program/sites',
    name: 'Site/Session',
    component: lazyLoad('components/program/Program'),
    props: {
      subHeaderTitle: 'SITE/SESSION',
      returnTo: '',
      path: '/account/program/sites',
      subHeader: true,
      bgColor: 'blue-theme'
    },
    meta: { name: 'Site/Session', gtm: 'Account Program Sites', reuse: false }
  },
  {
    path: '/home',
    name: 'Home',
    component: lazyLoad('components/rootcomponent/RootComponent'),
    props:
      currentLogedInRole() > 0 && currentLogedInRole() < 3
        ? {
          subHeaderTitle: 'Partner Accounts',
          returnTo: '',
          path: '/home',
          subHeader: true,
          bgColor: 'blue-theme'
        }
        : {
          subHeaderTitle: 'HOME',
          returnTo: '',
          path: '/home',
          subHeader: false,
          bgColor: 'blue'
        },
    meta: {
      name: 'Administration Home',
      gtm: 'Home',
      excludeDefaultBackgroundPatterns: false,
      analytics: {
        eventName: analyticsEventNames.DASHBOARD_VIEWED
      }
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      if (
        currentLogedInRole() > APP_CONST.ZERO &&
        currentLogedInRole() <= APP_CONST.THREE
      ) {
        if (to.matched && to.matched.length > 0) {
          to.matched[0].props.default = {
            subHeaderTitle: 'Partner Accounts',
            returnTo: '',
            path: '/home',
            subHeader: true,
            bgColor: 'blue-theme'
          };
        }
      }
      to.meta.excludeDefaultBackgroundPatterns = [
        APP_CONST.ROLES.PROGRAM_ADMIN,
        APP_CONST.ROLES.SESSION_ADMIN,
        APP_CONST.ROLES.STAFF
      ].includes(currentLogedInRole());

      const { accountId } = APP_UTILITIES.coreids();

      if (!accountId) {
        next();
        return;
      }

      const { data: { isDirectAccess = false } } = await getaccountbyid({ accountId });
      const userRole = currentLogedInRole();

      const rolesToRedirect = [
        APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN,
        APP_CONST.ROLE_TYPE_PROGRAM_ADMIN,
        APP_CONST.ROLE_TYPE_SESSION_ADMIN,
        APP_CONST.ROLE_TYPE_STAFF
      ];

      const learningExperienceDirectAccessEnabled = await APP_UTILITIES.getFeatureFlag(
        APP_CONST.FEATURE_KEYS.learningExperienceDirectAccess
      );

      const hasDirectAccessEnabled = learningExperienceDirectAccessEnabled && isDirectAccess;

      if (hasDirectAccessEnabled && userRole && rolesToRedirect.includes(userRole)) {
        next({
          name: APP_CONST.APP_ROUTES.CMS_MY_CONTENT.name
        });

        return;
      }

      next();
    }
  },
  {
    path: '/home',
    name: 'HomeProgAdmin',
    component: lazyLoad(
      'components/admindashboardcomponent/AdminDashboardComponent'
    ),
    meta: {
      name: 'BellXcel: Program Admin',
      gtm: 'Home',
      excludeDefaultBackgroundPatterns: true,
      analytics: {
        eventName: analyticsEventNames.DASHBOARD_VIEWED
      }
    },
    props: {
      subHeaderTitle: 'HOME',
      returnTo: '',
      path: '/home',
      subHeader: false,
      bgColor: 'blue'
    }
  },
  {
    path: '/home',
    name: 'HomeSessionAdmin',
    component: lazyLoad(
      'components/admindashboardcomponent/AdminDashboardComponent'
    ),
    meta: {
      name: 'BellXcel: Session Admin',
      gtm: 'Home',
      excludeDefaultBackgroundPatterns: true,
      analytics: {
        eventName: analyticsEventNames.DASHBOARD_VIEWED
      }
    },
    props: {
      subHeaderTitle: 'HOME',
      returnTo: '',
      path: '/home',
      subHeader: false,
      bgColor: 'blue'
    }
  },
  {
    path: '/home',
    name: 'HomeStaff',
    component: lazyLoad(
      'components/admindashboardcomponent/AdminDashboardComponent'
    ),
    meta: {
      name: 'BellXcel: Staff',
      gtm: 'Home',
      excludeDefaultBackgroundPatterns: true,
      analytics: {
        eventName: analyticsEventNames.DASHBOARD_VIEWED
      }

    },
    props: {
      subHeaderTitle: 'HOME',
      returnTo: '',
      path: '/home',
      subHeader: false,
      bgColor: 'blue'
    }
  },
  {
    path: '/partnerhome',
    name: 'Partner Home',
    component: lazyLoad('components/partnerHome/partnerHome'),
    meta: {
      name: 'BellXcel: Partner Accounts',
      gtm: 'Partner Home',
      excludeDefaultBackgroundPatterns: true,
      analytics: {
        eventName: analyticsEventNames.LOGIN
      }
    },
    props: true,
    beforeEnter: async (to: any, from: any, next: any) => {
      const { accountId } = APP_UTILITIES.coreids();

      if (!accountId) {
        next();
        return;
      }

      const { data: { isDirectAccess = false } } = await getaccountbyid({ accountId });
      let userRole = APP_UTILITIES.getCookie('highest_role');
      const isSuperUserDrillThrough: boolean = JSON.parse(APP_UTILITIES.getCookie('super_user_drilldown') || 'false');

      if (isSuperUserDrillThrough) {
        const [{ roleId }] = JSON.parse(APP_UTILITIES.getCookie('user_role') || '[{}]');

        if (roleId) {
          userRole = roleId;
        }
      }

      const rolesToRedirect = [
        APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN,
        APP_CONST.ROLE_TYPE_PROGRAM_ADMIN,
        APP_CONST.ROLE_TYPE_SESSION_ADMIN,
        APP_CONST.ROLE_TYPE_STAFF
      ];

      const learningExperienceDirectAccessEnabled = await APP_UTILITIES.getFeatureFlag(
        APP_CONST.FEATURE_KEYS.learningExperienceDirectAccess
      );

      const hasDirectAccessEnabled = learningExperienceDirectAccessEnabled && isDirectAccess;

      if (hasDirectAccessEnabled && userRole && rolesToRedirect.includes(parseInt(userRole))) {
        next({
          name: APP_CONST.APP_ROUTES.CMS_MY_CONTENT.name
        });

        return;
      }

      next();
    }
  },
  {
    path: '/users',
    name: 'Manage Users',
    component: lazyLoad('components/accountlistcomponent/AccountListComponent'),
    props: {
      subHeaderTitle: 'MANAGE USERS',
      returnTo: '',
      path: '/users',
      subHeader: true,
      bgColor: 'blue-theme'
    },
    meta: { name: 'Manage Users', gtm: 'Manage Users' }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: lazyLoad('commoncomponents/unauthcomponent/UnauthComponent'),
    props: {
      subHeaderTitle: 'Unauthorized',
      returnTo: '',
      path: '/unauthorized',
      subHeader: false
    },
    meta: { name: 'Unauthorized Access', gtm: 'Unauthorized Access' }
  },
  {
    path: '/accounts/create',
    name: 'New Account',
    component: lazyLoad('components/newaccount/newAccount'),
    props: {
      subHeaderTitle: 'ACCOUNT DETAILS',
      returnTo: '/home',
      path: '/accounts/create',
      subHeader: true,
      bgColor: 'blue-theme'
    },
    meta: { name: 'Add New Account', gtm: 'New Account' }
  },
  {
    path: '/accounts/edit',
    name: 'Edit Account',
    component: lazyLoad('components/newaccount/newAccount'),
    props: {
      subHeaderTitle: 'ACCOUNT DETAILS',
      returnTo: '/home',
      path: '/accounts/edit',
      subHeader: true,
      bgColor: 'blue-theme'
    },
    meta: { name: 'Edit Account', gtm: 'Edit Account' }
  },
  {
    path: '/configure/programadmin',
    name: 'InviteProgram',
    component: lazyLoad('components/inviteProgramAdmin/InviteProgram'),
    meta: { name: 'Invite Program Admin', gtm: 'Invite Program' },
    props: {
      subHeaderTitle: 'INVITE PROGRAM ADMIN',
      returnTo: '/home',
      path: '/configure/programadmin',
      subHeader: true,
      bgColor: 'blue-theme'
    }
  },
  {
    path: '/planner',
    name: 'Program Planner',
    component: lazyLoad(
      'components/plannerComponent/container/plannerComponent/PlannerComponent'
    ),
    meta: { name: 'Program Planner', gtm: 'Program Planner', analytics: { eventName: analyticsEventNames.PROGRAM_PLANNER_VIEWED } },
    props: {
      subHeaderTitle: 'PROGRAM PLANNER',
      returnTo: '',
      path: '/planner',
      subHeader: true,
      bgColor: 'purple-theme'
    }
  },
  {
    path: '/home',
    name: 'Admin Dashboard',
    component: lazyLoad(
      'components/admindashboardcomponent/AdminDashboardComponent'
    ),
    meta: { name: 'Admin Dashboard', gtm: 'Admin Dashboard', analytics: { eventName: analyticsEventNames.DASHBOARD_VIEWED } },
    props: {
      subHeaderTitle: ' PLANNER',
      returnTo: '',
      path: '/home',
      subHeader: true,
      bgColor: 'blue'
    }
  },
  {
    path: '/staffroster/new',
    name: 'Staff',
    component: lazyLoad('components/staffManagement/container/StaffRoster'),
    props: {
      subHeaderTitle: 'UPLOAD CSV',
      returnTo: '',
      path: '/staffroster/new',
      subHeader: true,
      bgColor: 'blue-theme'
    },
    meta: { name: 'New Staff', gtm: 'New Staff', reuse: false }
  },
  {
    path: '/attendance',
    name: 'Attendance',
    component: lazyLoad('components/attandanceComponent/AttandanceComponent'),
    meta: { name: 'Attendance', gtm: 'Attendance', reuse: false, analytics: { eventName: analyticsEventNames.ATTENDANCE_PAGE_VIEWED } },
    props: {
      subHeaderTitle: 'ATTENDANCE',
      returnTo: '',
      path: '/attendance',
      subHeader: true,
      bgColor: 'green'
    }
  },
  {
    path: '/staffroster',
    name: 'Staff Roster',
    component: lazyLoad('components/managesitecomponent/ManageSiteComponent'),
    meta: { name: 'Staff Roster', gtm: 'Staff Roster', reuse: false },
    props: {
      subHeaderTitle: 'Staff Roster',
      returnTo: '',
      path: '/staffroster',
      subHeader: true,
      bgColor: 'blue-theme'
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (to.query && to.query.tabSelected) {
        next({
          name: APP_CONST.STAFF_ROSTER,
          params: { tabSelected: 'manage-staff' },
          query: {}
        });
      }
      else {
        next();
      }
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: lazyLoad(
      'components/datadashboardcomponent/DataDashboardComponent'
    ),
    meta: { name: 'Data Dashboard', gtm: 'Data Dashboard', reuse: false },
    props: {
      subHeaderTitle: 'DATA DASHBOARD',
      returnTo: '',
      path: '/dashboard',
      subHeader: true,
      bgColor: 'blue-light-theme'
    }
  },
  {
    path: '/analytics/scholar',
    name: 'Scholar Analytics',
    component: lazyLoad(
      'components/analyticsComponent/scholarAnalyticsComponent/ScholarAnalyticsComponent'
    ),
    meta: {
      name: 'Analytics',
      gtm: 'Analytics',
      reuse: false,
      excludeDefaultBackgroundPatterns: true
    },
    props: {
      subHeaderTitle: 'SCHOLAR',
      returnTo: '',
      path: '/analytics/scholar',
      subHeader: true,
      bgColor: 'blue-light-theme'
    },
    children: [
      {
        path: '/',
        redirect: '/analytics/scholar/enrollment'
      },
      {
        path: '/analytics/scholar/enrollment',
        name: 'Scholar Enrollment Analytics',
        component: lazyLoad(
          'components/analyticsComponent/scholarAnalyticsComponent/scholarEnrollmentAnalyticsComponent/ScholarEnrollmentAnalyticsComponent'
        ),
        meta: {
          name: 'Scholar Analytics',
          gtm: 'Scholar Analytics',
          reuse: false,
          excludeDefaultBackgroundPatterns: true,
          analytics: {
            eventName: analyticsEventNames.ENROLLMENT_ANALYTICS_VIEWED
          }
        },
        props: {
          subHeaderTitle: 'SCHOLAR',
          returnTo: '',
          path: '/analytics/scholar/enrollment',
          subHeader: true,
          bgColor: 'blue-light-theme'
        }
      },
      {
        path: '/analytics/scholar/application',
        name: 'Scholar Application Analytics',
        component: lazyLoad(
          'components/analyticsComponent/scholarAnalyticsComponent/scholarApplicationAnalyticsComponent/ScholarApplicationAnalyticsComponent'
        ),
        meta: {
          name: 'Scholar Analytics',
          gtm: 'Scholar Analytics',
          reuse: false,
          excludeDefaultBackgroundPatterns: true,
          analytics: {
            eventName: analyticsEventNames.APPLICATION_ANALYTICS_VIEWED
          }
        },
        props: {
          subHeaderTitle: 'SCHOLAR',
          returnTo: '',
          path: '/analytics/scholar/application',
          subHeader: true,
          bgColor: 'blue-light-theme'
        }
      },
      {
        path: '/analytics/scholar/attendance',
        name: 'Scholar Attendance Analytics',
        component: lazyLoad(
          'components/analyticsComponent/scholarAnalyticsComponent/scholarAttendanceAnalyticsComponent/ScholarAttendanceAnalyticsComponent'
        ),
        meta: {
          name: 'Scholar Analytics',
          gtm: 'Scholar Analytics',
          reuse: false,
          excludeDefaultBackgroundPatterns: true
        },
        props: {
          subHeaderTitle: 'SCHOLAR',
          returnTo: '',
          path: '/analytics/scholar/attendance',
          subHeader: true,
          bgColor: 'blue-light-theme'
        },
        children: [
          {
            path: '/',
            redirect: '/analytics/scholar/attendance/ada'
          },
          {
            path: '/analytics/scholar/attendance/ada',
            name: 'Scholar Attendance ADA Analytics',
            component: lazyLoad(
              'components/analyticsComponent/scholarAnalyticsComponent/scholarAttendanceAnalyticsComponent/adaAttendanceAnalyticsComponent/ADAAttendanceAnalyticsComponent'
            ),
            meta: {
              name: 'Scholar Analytics',
              gtm: 'Scholar Analytics',
              reuse: false,
              excludeDefaultBackgroundPatterns: true,
              analytics: {
                eventName: analyticsEventNames.ADA_ANALYTICS_VIEWED
              }
            },
            props: {
              subHeaderTitle: 'SCHOLAR',
              returnTo: '',
              path: '/analytics/scholar/attendance/ada',
              subHeader: true,
              bgColor: 'blue-light-theme'
            }
          },
          {
            path: '/analytics/scholar/attendance/completion',
            name: 'Scholar Attendance Completion Analytics',
            component: lazyLoad(
              'components/analyticsComponent/scholarAnalyticsComponent/scholarAttendanceAnalyticsComponent/completionAttendanceAnalyticsComponent/CompletionAttendanceAnalyticsComponent'
            ),
            meta: {
              name: 'Scholar Analytics',
              gtm: 'Scholar Analytics',
              reuse: false,
              excludeDefaultBackgroundPatterns: true,
              analytics: {
                eventName: analyticsEventNames.ANALYTICS_ATTENDANCE_COMPLETION_VIEWED
              }
            },
            props: {
              subHeaderTitle: 'SCHOLAR',
              returnTo: '',
              path: '/analytics/scholar/attendance/completion',
              subHeader: true,
              bgColor: 'blue-light-theme'
            }
          }
        ]
      },
      {
        path: '/analytics/scholar/retention',
        name: 'Scholar Retention Analytics',
        component: lazyLoad(
          'components/analyticsComponent/scholarAnalyticsComponent/scholarRetentionAnalyticsComponent/ScholarRetentionAnalyticsComponent'
        ),
        meta: {
          name: 'Scholar Analytics',
          gtm: 'Scholar Analytics',
          reuse: false,
          excludeDefaultBackgroundPatterns: true,
          analytics: {
            eventName: analyticsEventNames.ANALYTICS_RETENTION_VIEWED
          }
        },
        props: {
          subHeaderTitle: 'SCHOLAR',
          returnTo: '',
          path: '/analytics/scholar/retention',
          subHeader: true,
          bgColor: 'blue-light-theme'
        }
      }
    ]
  },
  {
    path: '/analytics/quality',
    name: 'Quality Analytics',
    component: lazyLoad(
      'components/analyticsComponent/qualityAnalyticsComponent/QualityAnalyticsComponent'
    ),
    meta: {
      name: 'Quality Analytics',
      gtm: 'Quality Analytics',
      reuse: false,
      excludeDefaultBackgroundPatterns: true
    },
    props: {
      subHeaderTitle: 'QUALITY',
      returnTo: '',
      path: '/analytics/quality',
      subHeader: true,
      bgColor: 'blue-light-theme'
    }
  },
  {
    path: '/analytics/professionalLearning',
    name: 'Professional Learning Analytics',
    component: lazyLoad(
      'components/analyticsComponent/professionalLearningAnalyticsComponent/ProfessionalLearningAnalyticsComponent'
    ),
    meta: {
      name: 'Professional Learning Analytics',
      gtm: 'Professional Learning Analytics',
      reuse: false,
      excludeDefaultBackgroundPatterns: true
    },
    props: {
      subHeaderTitle: 'PROFESSIONAL DEVELOPMENT',
      returnTo: '',
      path: '/analytics/professionalLearning',
      subHeader: true,
      bgColor: 'blue-light-theme'
    }
  },
  {
    path: '/analytics/familyEngagement',
    name: 'Family Engagement Analytics',
    component: lazyLoad(
      'components/analyticsComponent/familyEngagementAnalyticsComponent/FamilyEngagementAnalyticsComponent'
    ),
    meta: {
      name: 'Family Engagement Analytics',
      gtm: 'Family Engagement Analytics',
      reuse: false,
      excludeDefaultBackgroundPatterns: true
    },
    props: {
      subHeaderTitle: 'FAMILY ENGAGEMENT',
      returnTo: '',
      path: '/analytics/familyEngagement',
      subHeader: true,
      bgColor: 'blue-light-theme'
    },
    children: [
      {
        path: '/',
        redirect: '/analytics/familyEngagement/family'
      },
      {
        path: '/analytics/familyEngagement/family',
        name: 'Family Engagement Analytics',
        component: lazyLoad(
          'components/analyticsComponent/familyEngagementAnalyticsComponent/familyTabFamilyEngagement/FamilyTabFamilyEngagement'
        ),
        meta: {
          name: 'Family Engagement Analytics',
          gtm: 'Family Engagement Analytics',
          reuse: false,
          excludeDefaultBackgroundPatterns: true
        },
        props: {
          subHeaderTitle: 'FAMILY ENGAGEMENT',
          returnTo: '',
          path: '/analytics/familyEngagement/family',
          subHeader: true,
          bgColor: 'blue-light-theme'
        }
      },
      {
        path: '/analytics/familyEngagement/team',
        name: 'Family Team Engagement Analytics',
        component: lazyLoad(
          'components/analyticsComponent/familyEngagementAnalyticsComponent/teamTabFamilyEngagement/TeamTabFamilyEngagement'
        ),
        meta: {
          name: 'Family Team Engagement Analytics',
          gtm: 'Family Team Engagement Analytics',
          reuse: false,
          excludeDefaultBackgroundPatterns: true
        },
        props: {
          subHeaderTitle: 'FAMILY ENGAGEMENT',
          returnTo: '',
          path: '/analytics/familyEngagement/team',
          subHeader: true,
          bgColor: 'blue-light-theme'
        }
      }
    ]
  },
  {
    path: '/ld-demo',
    name: 'Launch Darkly Example',
    component: lazyLoad(
      'components/launchdarklycomponent/LaunchDarklyComponent'
    ),
    meta: {
      flag: 'playground'
    },
    props: {
      subHeaderTitle: 'LAUNCH DARKLY EXAMPLE',
      returnTo: '',
      path: '/ld-demo',
      subHeader: true,
      bgColor: 'blue-light-theme'
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      await flagGuard(to, from, next);
    }
  }
];

export default routes;
