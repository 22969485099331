const saveInLocalStorage = (key, value) => {
  let strKey = key, 
    strValue = value;
  if(typeof key !== 'string') {
    strKey = key + '';
  }

  if(typeof value === 'object') {
    strValue = JSON.stringify(value);
  }

  localStorage.setItem(strKey, strValue);
};

const getFromLocalStorage = (key) => {
  let strKey = key;

  if(typeof key !== 'string') {
    strKey = key + '';
  }

  return localStorage.getItem(strKey);
};

const deleteFromLocalStorage = key => {
  if(typeof key !== 'string') {
    key = key + '';
  }
    
  localStorage.removeItem(key);
  
};

export default {
  saveInLocalStorage, 
  getFromLocalStorage,
  deleteFromLocalStorage
};