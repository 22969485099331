import { httpClient, httpPlannerClient, httpEnrollmentClient, httpLMSClient, httpClientFileUploadFormData, httpCMSAnalyticsClient } from '../common/httpClient';
import { PrintPayload, DownloadScholarPayload, DownloadSitePayload, DownloadScholarDetailsPayload, DownloadConsolidatedScholar, QualityPayload, QualityScorePayload } from '@/Model/model';
import API_CONST from '@/constants/ApiConst';
import APP_CONST from '@/constants/AppConst';
import programConfigure from '@/store/modules/programConfigure';

export function getTaskEvent(args: any) {
  return httpPlannerClient().get(`${API_CONST.DASHBOARD_TASK_EVENT_ENDPOINT}?productId=${args.productId}&programId=${args.programId}&siteId=${args.siteId}&startdate=${args.startDate}&enddate=${args.endDate}&isdrilldown=${args.isDrillDown}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function condenseTaskUpdateService(args: any) {
  args.productId = programConfigure.productId;
  return httpPlannerClient().put(API_CONST.UPDATE_PROGRAM_TASK + '/' + args.id, args).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


export function getRecruitmentData(programId: number, date: any) {
  return httpClient().get(`${API_CONST.GET_RECRUITMENT_BY_PROGRAMID}?programId=${programId}&date=${date}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


export function getRetention(programId: number, siteId: number, isDashboard: boolean) {
  return httpClient().get(`${API_CONST.GET_RETENTION}?freq=2&programId=${programId}&siteId=${siteId}&isDashboard=${isDashboard}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
export function getQualityData(programId: number, siteId: number) {
  return httpClient().get(`${API_CONST.GETQUALITY_DATA}?programId=${programId}&siteId=${siteId}&allDate=${true}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
export function getAssementQualityData(programId: number, siteId: number, date: string) {
  return httpClient().get(`${API_CONST.QUALITY_SCORE + '/qualityscores/'}?programId=${programId}&siteId=${siteId}&assementDate=${date}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getRetentionBySite(programId: number) {
  return httpClient().get(`${API_CONST.GET_RETENTION_BY_SITE}/program/${programId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getAda(programId: number, siteId: number, isDashboard: boolean) {
  return httpClient().get(`${API_CONST.GET_ADA}?freq=2&programId=${programId}&siteId=${siteId}&isDashboard=${isDashboard}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getAdaBySite(programId: number) {
  return httpClient().get(`${API_CONST.GET_ADA_BY_SITE}/program/${programId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function fetchLMSUrl(id: number) {
  return httpLMSClient().get(`token/${id}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function fetchTiRedirectURL(redriectURI: string) {
  return httpLMSClient().get(`token/5?redirectURI=${redriectURI}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export async function fetchSiteList(id: string) {
  return await httpClient().get(`/sites?Id=${id}&Page=1&SortField=sitename&SortDir=1`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function printHTMLTOPdf(payload: PrintPayload) {
  return httpClient().post(`${API_CONST.HTML_TO_PDF}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function downLoadFileByScholar(payload: DownloadScholarPayload) {
  return httpClient().get(`${API_CONST.GET_DAILY_ATTEDANCE_BY_SCHOLAR_REPORT}?ClassId=${payload.classId}&SiteId=${payload.siteId}&ProgramId=${payload.programId}&StartDate=${payload.startDate}&EndDate=${payload.endDate}&SortField=${payload.sortField}&SortDir=${payload.sortDir}&group=${payload.group}&isReport=${payload.isReport}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function downLoadFileBySite(payload: DownloadSitePayload) {
  return httpClient().get(`${API_CONST.GET_ADA_BY_SITE_REPORT}?programId=${payload.programId}&aDAReportType=${payload.adaReportType}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function downLoadFileByWeekReport(payload: DownloadSitePayload) {
  return httpClient().get(`${API_CONST.GET_ADA_BY_SITE_REPORT}?programId=${payload.programId}&aDAReportType=${payload.adaReportType}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function downLoadFileWithScholarDetails(payload: DownloadScholarDetailsPayload) {
  return httpClient().get(`${API_CONST.GET_DAILY_ATTEDANCE_WITH_SCHOLAR_DETAILS}?ClassId=${payload.classId}&SiteId=${payload.siteId}&ProgramId=${payload.programId}&StartDate=${payload.startDate}&EndDate=${payload.endDate}&SortField=${payload.sortField}&SortDir=${payload.sortDir}&group=${payload.group}&isReport=${payload.isReport}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function downloadConsolidatedRoaster(payload : DownloadConsolidatedScholar) {
  return httpEnrollmentClient().post(`${API_CONST.DOWNLOAD_ROASTER}?accountId=${payload.accountId}&siteId=${payload.siteId}&programId=${payload.programId}&step=${payload.step}&filetype=${payload.fileType}`,
    {
      // It needs to be present in the request body
      ColumnsToHide: null
    }
  ).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function addQualityScore(payload: QualityPayload) {
  return httpClient().post(`${API_CONST.QUALITY_SCORE}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function editQualityScore(payload: QualityPayload, id: number) {
  return httpClient().put(`${API_CONST.QUALITY_SCORE}/${id}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function downLoadFileOfQualityScreener() {
  return httpClient().get(`${API_CONST.GET_QUALITY_SCREENER_PDF}`, { responseType: 'arraybuffer' }).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function showWelcomePopup(payload: any) {
  return httpClient().get(`${API_CONST.QUALITY_WELCOME}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function postWelcomeInformation(payload: any) {
  return httpClient().post(`${API_CONST.QUALITY_WELCOME}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
export function uploadQualityFile(payload: any) {
  return httpClientFileUploadFormData().post(`${API_CONST.QUALITY_SCORE + '/uploadfile'}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function downLoadFileOfQualityScore(payload: QualityScorePayload) {
  return httpClient().get(`${API_CONST.GET_QUALITY_SCORE_CSV}?programId=${payload.programId}&siteId=${payload.siteId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function deleteQualityScore(id: number) {
  return httpClient().delete(`${API_CONST.QUALITY_SCORE}?id=${id}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getApplicationData(programId: number, applicationIds: any) {
  applicationIds = applicationIds && applicationIds.length && applicationIds.map((element: string) => {
    return element.toUpperCase();
  });
  const applicationId = applicationIds && applicationIds.length
    ? applicationIds.join(',')
    : APP_CONST.BLANK;
  return httpCMSAnalyticsClient().get(`${API_CONST.ANALYTICS_TOTAL_SUBMISSIONS}?programId=${programId}&applicationIds=${applicationId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getFamilyEngagementFamilyData(accountId: number) {
  return httpEnrollmentClient().get(`${API_CONST.FAMILY_ENGAGEMENT}/account/${accountId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getFamilyEngagementTeamData(accountId: number) {
  return httpEnrollmentClient().get(`${API_CONST.CHAT_FAMILY_ENGAGEMENT}/account/${accountId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


