import {Vue} from 'vue-property-decorator';
import * as errormessages from '@/lang/en/errormessages.json';

export class ErrorMessages extends Vue{

  public getErrorMessages(key:string):string{
    const strErrorMessages:string = (<any> errormessages.en)[key];
    return strErrorMessages;
  }


}