import { httpClient, httpIdentityClient, httpRosterClient, httpClientEnrollment } from '../common/httpClient';
import API_CONST from '@/constants/ApiConst';
import { ICreateUser } from '@/Model/programModel';
import { StaffPayload } from '@/Model/model';

export function getRoles(type: number){
  return httpClient().get(`${process.env.VUE_APP_BASE_IDENTITY_URL}${API_CONST.GET_ROLES}/${type}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}

export function addUser(payload: ICreateUser){
  return httpClient().post(process.env.VUE_APP_BASE_IDENTITY_URL + API_CONST.USERS, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function deleteUser(userId: number) {    
  return httpClient().delete(`${process.env.VUE_APP_BASE_IDENTITY_URL}${API_CONST.USERS}/${userId}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function resendInvite(email: string) {
  return httpClient().post(`${process.env.VUE_APP_BASE_IDENTITY_URL}${API_CONST.RESEND_INVITE}`, {email:email}).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  
  }).catch((error) => {
    const res = error;
    return { data : res.data.Message, status: res.status, statusText: res.statusText };
  });
}

export function getAccounts(){
  return httpClient().get(`${API_CONST.GET_ACCOUNTS}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}

export function editUser(payload: any){
  return httpClient().put(`${process.env.VUE_APP_BASE_IDENTITY_URL}${API_CONST.USERS}/${payload.id}`, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function getProgramByAccount(accountId: number){
  return httpClient().get(`${API_CONST.GET_PROGRAMS_BY_ACCOUNT}/account/${accountId}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}

export function getStaff(){
  return httpClient().get(`${process.env.VUE_APP_BASE_IDENTITY_URL}${API_CONST.GET_STAFF}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}


export function getSiteByProgramId(programId: number){
  return httpClient().get(`${API_CONST.GET_SITE_BY_PROGRAMID}/program/${programId}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}

export function getClassroomsBySite(programId:number,siteId: number){
  return httpClient().get(`${API_CONST.POST_SITE_DATA}/${siteId}/program/${programId}/${API_CONST.CLASSROOMS}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}

export function getStaffByProgramId(payload: StaffPayload){
  return httpClient().get(`${process.env.VUE_APP_BASE_IDENTITY_URL}${API_CONST.GET_STAFF_LIST}`, {params: payload}).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    return { data : error.data, status: error.status, statusText: error.statusText};
  });
}
  
export function postScholarData(payload: any){
  return httpClient().post(`${API_CONST.POST_SCHOLAR_DATA}`, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function postScholarDataEnrollment(payload: any){
  return httpClientEnrollment().post(`${API_CONST.POST_SCHOLAR_DATA_Enrollment}`, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function postFamilyData(siteId:number, scholarId:string, payload: any){
  return httpClient().post(`${API_CONST.POST_FAMILY_DATA}?scholarId=${scholarId}&siteId=${siteId}`, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}
 
export function getHeadersDropdown(){
  return httpRosterClient().get(`${API_CONST.GET_DROPDOWN_VALUES}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}


export function updateScholarData(payload: any){
  return httpClient().put(`${API_CONST.UPDATE_SCHOLAR_DATA}${payload.id}`, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function updateScholarContactsData(payload: any){
  return httpClient().put(`${API_CONST.UPDATE_SCHOLAR_CONTACTS_DATA}/families`, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

  
export function deleteFamily(payload: any){
  return httpClient().delete(`${API_CONST.DELETE_FAMILY}${payload.id}&mappingId=${payload.scholarprogramsitemapping}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

  
export function getAddedScholarData(payload: any){
  return httpClient().get(`${API_CONST.GET_ADDED_SCHOLAR}${payload.id}&siteId=${payload.siteId}&programId=${payload.programId}`).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function getUserEmailStatus(email: string, id:number | undefined){
  return httpIdentityClient().post(`${API_CONST.USER_EMAIL}`, {Email:email, userId: id}).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function editPartnerUserDetails(payload: ICreateUser){
  const id = payload.id;
  return httpIdentityClient().put(`${API_CONST.USERS}/${id}`, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function getProgramDetailsById(id:number){
  return httpClient().get(`${API_CONST.PROGRAMS}/${id}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getSitesByProgramId(payload: any){
  let queryString = '';
  const args:any = payload;
  for (const k in args) {
    if (args!== 'isExactMatch' && args[k]) {
      if (k == 'search') {
        args[k] = encodeURIComponent(args[k]);
      }
      queryString = queryString + k + '=' + args[k] + '&';
    }
    else{
      queryString = queryString + k + '=' + args[k] + '&';
    }
  }
  queryString = queryString.slice(0, -1);
  return httpClient().get(API_CONST.GET_SITE_BY_PROGRAMID + '?' +queryString).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function addPartnerUser(payload: ICreateUser){
  return httpClient().post(process.env.VUE_APP_BASE_IDENTITY_URL + API_CONST.PARTNER_USERS, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}

export function getPrimaryAccountAdmin(id:number){
  return httpIdentityClient().get(`${API_CONST.USERS}/${API_CONST.ACCOUNTS}/${id}/${API_CONST.PRIMARY_ADMIN}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function deleteBulkSite(payload:any) {
  const encodedSearch = decodeURIComponent(payload.search);
  payload.search = encodedSearch;
  return httpClient().delete(`${API_CONST.DELETE_BULK_SITES}`,{data:payload}).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


export function editPartnerUser(payload:ICreateUser){
  return httpClient().put(`${process.env.VUE_APP_BASE_IDENTITY_URL}${API_CONST.PARTNER_USERS}/${payload.id}`, payload).then((response) => {
    return { data : response.data, status: response.status, statusText: response.statusText};
  }).catch((error) => {
    const res = error;
    return { data : (res.data.Message || res.data.value || res.data), status: res.status, statusText: res.statusText };
  });
}
