
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import './validators';
import router from './router';
import store from './store';
import Datepicker from 'vuejs-datepicker';
import vClickOutside from 'v-click-outside';
import 'beautify-scrollbar/dist/index.css';
import V2LazyList from 'v2-lazy-list';
import Vue2TouchEvents from 'vue2-touch-events';
import VueScrollTo from 'vue-scrollto';
import Vuetify from 'vuetify';
import DaySpanVuetify from 'dayspan-vuetify';
import VueTimepicker from 'vue2-timepicker';
import VueClipboard from 'vue-clipboard2';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import 'vue2-timepicker/dist/VueTimepicker.css';

import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css';
import 'vuetify/dist/vuetify.min.css';
import ScrollSync from 'vue-scroll-sync';
import VueGtm from 'vue-gtm';
import APP_CONST from './constants/AppConst';
import PortalVue from 'portal-vue';

// Import the plugin here
import { Auth0Plugin } from './auth';
import { analyticsPlugin } from './analytics';
import { segmentAnalyticsService } from './analytics/segment';

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID
  // onRedirectCallback: (appState: any) => {
  //   const { slug } = JSON.parse(
  //     String(APP_UTILITIES.getCookie("accountDesign"))
  //   );
  //   window.history.replaceState(
  //     {},
  //     document.title,
  //     slug ? `${window.location.origin}/${slug}` : window.location.pathname
  //   );
  // }
});

/**
 * Register the analytics plugin to enable customer data collection.
 */
Vue.use(analyticsPlugin, {
  // CDP: twilio segment (https://segment.com/)
  service: segmentAnalyticsService,
  router,
});

VueClipboard.config.autoSetContainer = true;

Vue.use(VueClipboard);
Vue.use(Vue2TouchEvents);
Vue.component('datepicker', Datepicker);
Vue.use(Vuetify);
Vue.use(vClickOutside);
Vue.use(V2LazyList);
Vue.use(VueScrollTo);
Vue.use(VueVirtualScroller);
Vue.component('scroll-sync', ScrollSync);
Vue.component('vue-timepicker', VueTimepicker);
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_CODE/* ,// 'GTM-T95XJMW'
  queryParams: {
    gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
    gtm_preview:'env-4',
    gtm_cookies_win:'x'
  } */,
  defer: false,
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
});
Vue.use(PortalVue);

Vue.directive('telnumberformat', {
  bind: function (el: any, binding: any, vnode: any) {
    binding.def.handleKeyUp(el);
  },
  update: function (el: any, binding: any, vnode: any) {
    binding.def.handleKeyUp(el);
  },
  inserted: (el: any, binding: any, vnode: any) => {
    el.addEventListener('keyup', (evt: any) => {
      if (
        evt.keyCode == 37 ||
        evt.keyCode == 38 ||
        evt.keyCode == 39 ||
        evt.keyCode == 40
      ) {
        return;
      }
      binding.def.handleKeyUp(el);
    });
  },
  handleKeyUp: (input: any) => {
    let val = input.value;

    let cursorIndex = input.selectionStart;

    const objReg = /\D/;
    for (let i = 0; i < cursorIndex; i++) {
      const sub = val.substr(i, 1);
      const test = objReg.test(sub.toString());
      if (test) {
        cursorIndex--;
      }
    }

    val = val.replace(/\D/g, '').substring(0, 10);

    if (cursorIndex < 3) {
      cursorIndex += 1;
    }
    else if (cursorIndex > 2 && cursorIndex < 6) {
      cursorIndex += 3;
    }
    else {
      cursorIndex += 4;
    }
    const zip = val && val.substring(0, 3);
    const middle = val && val.substring(3, 6);
    const last = val && val.substring(6, 10);
    if (val.length == 0) {
      val = '';
    }
    else if (val.length < 4) {
      val = `(${zip}`;
    }
    else if (val.length < 7) {
      val = `(${zip}) ${middle}`;
    }
    else {
      val = `(${zip}) ${middle}-${last}`;
    }

    input.value = val;
    const phone_error = document.querySelector('#phone_validation');
    if (phone_error) {
      phone_error.remove();
    }
  }
} as any);

Vue.directive('zip', {
  bind: function (el: any, binding: any, vnode: any) {
    binding.def.handleKeyUp(el);
  },
  inserted: (el: any, binding: any, vnode: any) => {
    el.addEventListener('keydown', (evt: any) => {
      const ASCIICode = evt.which
        ? evt.which
        : evt.keyCode;
      if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
        evt.preventDefault();
        return false;
      }
    });
    el.addEventListener('keyup', (evt: any) => {
      if (
        evt.keyCode == 37 ||
        evt.keyCode == 38 ||
        evt.keyCode == 39 ||
        evt.keyCode == 40
      ) {
        return;
      }

      binding.def.handleKeyUp(el);
    });
  },
  handleKeyUp: (input: any) => {
    let val = input.value;

    let cursorIndex = input.selectionStart;

    const objReg = /\D/;
    for (let i = 0; i < cursorIndex; i++) {
      const sub = val.substr(i, 1);
      const test = objReg.test(sub.toString());
      if (test) {
        cursorIndex--;
      }
    }

    val = val.replace(/\D/g, '').substring(0, 5);
    input.value = val;
    const zipCode_error = document.querySelector('#zip_validation');
    if (zipCode_error) {
      zipCode_error.remove();
    }
    if (input.value.length > 0 && input.value.length < 5) {
      const node = document.createElement('span');
      node.id = 'zip_validation';
      const textnode = document.createTextNode('Zip Code is invalid');
      node.appendChild(textnode);
      node.setAttribute('style', `color:#${APP_CONST.STYLE.COLOR.DANGER_700}`);
      input.parentNode.appendChild(node);
      input.setAttribute('style', `border: 1px solid ${APP_CONST.STYLE.COLOR.DANGER_700};`);
    }
    else {
      zipCode_error && zipCode_error.remove();
      input.setAttribute('style', `border: 1px solid ${APP_CONST.STYLE.COLOR.NEUTRAL_500};`);
    }
    input.setSelectionRange(cursorIndex, cursorIndex);
  }
} as any);

Vue.use(DaySpanVuetify, {
  data: {
    defaults: {
      dsCalendar: {
        handleAdd: false,
        handleMove: false
      },
      dsCalendarEventPopover: {
        allowEditOnReadOnly: false
      },
      dsCalendarApp: {
        allowsAddToday: false,
        types: [
          { id: 'W', label: 'Week', shortcut: 'W', type: 1, size: 1, focus: 0.4999, repeat: true, listTimes: true, updateRows: true, schedule: false },
          { id: 'M', label: 'Month', shortcut: 'M', type: 2, size: 1, focus: 0.4999, repeat: true, listTimes: false, updateRows: true, schedule: false },
        ]
      }
    },
  },
  methods: {
    getDefaultEventColor: () => APP_CONST.STYLE.COLOR.PRIMARY_700
  }
});

Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
