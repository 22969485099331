import { httpCMSClient, httpPlannerClient, httpEventClient, httpIdentityClient, httpClient, httpClientFileUploadFormData } from '../common/httpClient';
import API_CONST from '@/constants/ApiConst';
import APP_UTILITIES from '@/utilities/commonFunctions';

export function assign(body: TaskSiteAssignDto) {
  return httpPlannerClient().post(`${API_CONST.ASSIGN_TASKS}`, body).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function skip(body: ISkipTaskDto) {
  return httpPlannerClient().post(`${API_CONST.SKIP_TASKS}?status=${body.status}`, body).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function ownerinfo(body: IOwnerInfoDto) {
  return httpPlannerClient().post(`${API_CONST.OWNER_INFO}`, body).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function printHTMLTOPdf(payload:any){
  return httpClient().post(`${API_CONST.HTML_TO_PDF_DOWNLOAD}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function putNewOwner(payload: IUpdateTaskOwnerDto) {
  return httpPlannerClient().put(`${API_CONST.TASK}${payload.id}/ownerstatus?ownerId=${payload.ownerId}&previousOwnerId=${payload.previousOwnerId}&siteId=${payload.siteId}&programId=${payload.programId}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function updateTaskStatus(payload: IUpdateTaskStatusDto) {
  return httpPlannerClient().put(`${API_CONST.TASK}${payload.id}/ownerstatus?status=${payload.status}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function tasks(body: IGetTaskDto) {
  const {accountId} = APP_UTILITIES.coreids();
  let url_ = API_CONST.GET_TASKS;
  if (body.programId === null) {
    throw new Error("The parameter 'programId' cannot be null.");
  }
  else if (body.programId !== undefined) {
    url_ += 'ProgramId=' + encodeURIComponent('' + body.programId) + '&';
  }
  if (accountId !== undefined) {
    url_ += 'AccountId=' + encodeURIComponent('' + accountId) + '&';
  }
  if (body.productId === null) {
    throw new Error("The parameter 'productId' cannot be null.");
  }
  else if (body.productId !== undefined) {
    url_ += 'ProductId=' + encodeURIComponent('' + body.productId) + '&';
  }
  if (body.id !== undefined && body.id !== null) {
    url_ += 'Id=' + encodeURIComponent('' + body.id) + '&';
  }
  if (body.active !== undefined && body.active !== null) {
    url_ += 'Active=' + encodeURIComponent('' + body.active) + '&';
  }
  if (body.search !== undefined && body.search !== null) {
    url_ += 'Search=' + encodeURIComponent('' + body.search) + '&';
  }
  if (body.page !== undefined && body.page !== null) {
    url_ += 'Page=' + encodeURIComponent('' + body.page) + '&';
  }
  if (body.count !== undefined && body.count !== null) {
    url_ += 'Count=' + encodeURIComponent('' + body.count) + '&';
  }
  if (body.sortField !== undefined && body.sortField !== null) {
    url_ += 'SortField=' + encodeURIComponent('' + body.sortField) + '&';
  }
  if (body.siteId !== undefined && body.siteId !== null) {
    url_ += 'SiteId=' + encodeURIComponent('' + body.siteId) + '&';
  }
  if (body.sortDir === null) {
    throw new Error("The parameter 'sortDir' cannot be null.");
  }
  else if (body.sortDir !== undefined) {
    url_ += 'SortDir=' + encodeURIComponent('' + body.sortDir) + '&';
  }
  url_ = url_.replace(/[?&]$/, '');
  return httpPlannerClient().get(url_).then((response: any) => {
    return { data: response.status == 200
      ? response.data
      : [], status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: [], status: error.status, statusText: error.statusText };
  });
}

export function updateSiteNotificationInfo(body: IGetTaskDto) {
  let url_ = API_CONST.UPDATE_SITENOTIFICATION;
  if (body.programId === null) {
    throw new Error("The parameter 'programId' cannot be null.");
  }
  else if (body.programId !== undefined) {
    url_ += 'ProgramId=' + encodeURIComponent('' + body.programId) + '&';
  }

  if (body.siteId !== undefined && body.siteId !== null) {
    url_ += 'SiteId=' + encodeURIComponent('' + body.siteId);
  }
  url_ = url_.replace(/[?&]$/, '');
  return httpPlannerClient().post(url_).then((response: any) => {
    return {};
  }).catch((error: any) => {
    return { };
  });
}

export function addTask(body: ITaskDetailDto) {
  return httpPlannerClient().post(`${API_CONST.PROG_TASK}`, body).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function editTask(body: ITaskDetailDto) {
  return httpPlannerClient().put(`${API_CONST.PROG_TASK}/${body.id}`, body).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function deleteTask(id: number) {
  return httpPlannerClient().delete(`${API_CONST.PROG_TASK}/${id}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getTask(taskId: number) {
  return httpCMSClient().post(`${API_CONST.PROG_TASK}`, taskId).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getTaskDetails(taskId: number) {
  return httpPlannerClient().get(`${API_CONST.PROG_TASK}?taskId=${taskId}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function addFile(type: number ,formData: any) {
  return httpPlannerClient().post(`${API_CONST.ADD_FILES}${type}`, formData).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function copyTask(payload :ICopyTaskDto) {
  return httpPlannerClient().get(`${API_CONST.COPY_FILES}${payload.taskId}${API_CONST.SITE_ID}${payload.siteId}${API_CONST.PROGRAM_ID}${payload.programId}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function addComment(payload: any) {
  return httpPlannerClient().post(`${API_CONST.DISCUSSION}`, payload).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function deleteEvent(payload:any){
  return httpEventClient().delete(`${API_CONST.DELETE_EVENT}/${payload.id}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function deleteholidayEvent(payload:any){
  return httpPlannerClient().delete(`${process.env.VUE_APP_BASE_URL}${API_CONST.GET_ALL_HOLIDAYS}/${payload.id}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
export function uploadHolidayFile(payload:any){
  return httpClientFileUploadFormData().post(`${API_CONST.UPLOAD_HOLIDAY_FILE}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
export function addEvent(payload:any){
  return httpEventClient().post(`${API_CONST.ADD_EVENT}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function updateEvent(payload:any,id:number){
  return httpEventClient().put(`${API_CONST.UPDATE_EVENT}/${id}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function editHolidays(payload: any) {
  return httpClient().put(`${API_CONST.GET_ALL_HOLIDAYS}/${payload.id}`, payload).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    const res = error;
    return { data: (res.data.Message || res.data.value || res.data), status: error.status, statusText: error.statusText };
  });
}

export function uploadEventFile(payload:any){
  return httpEventClient().post(`${API_CONST.ADD_FILE_EVENTS}${2}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function copyEvent(eventId : number) {
  return httpEventClient().post(`${API_CONST.COPY_EVENTS}`,{eventId}).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function events(body: any) {
  let url_ = API_CONST.GET_EVENTS;
  if (body.programId === null) {
    throw new Error("The parameter 'programId' cannot be null.");
  }
  else if (body.programId !== undefined) {
    url_ += 'ProgramId=' + encodeURIComponent('' + body.programId) + '&';
  }
  if (body.productId === null) {
    throw new Error("The parameter 'productId' cannot be null.");
  }
  else if (body.productId !== undefined) {
    url_ += 'ProductId=' + encodeURIComponent('' + body.productId) + '&';
  }
  if (body.id !== undefined && body.id !== null) {
    url_ += 'Id=' + encodeURIComponent('' + body.id) + '&';
  }
  if (body.active !== undefined && body.active !== null) {
    url_ += 'Active=' + encodeURIComponent('' + body.active) + '&';
  }
  if (body.search !== undefined && body.search !== null) {
    url_ += 'Search=' + encodeURIComponent('' + body.search) + '&';
  }
  if (body.page !== undefined && body.page !== null) {
    url_ += 'Page=' + encodeURIComponent('' + body.page) + '&';
  }
  if (body.count !== undefined && body.count !== null) {
    url_ += 'Count=' + encodeURIComponent('' + body.count) + '&';
  }
  if (body.sortField !== undefined && body.sortField !== null) {
    url_ += 'SortField=' + encodeURIComponent('' + body.sortField) + '&';
  }
  if (body.siteId !== undefined && body.siteId !== null) {
    url_ += 'SiteId=' + encodeURIComponent('' + body.siteId) + '&';
  }
  if (body.sortDir === null) {
    throw new Error("The parameter 'sortDir' cannot be null.");
  }
  else if (body.sortDir !== undefined) {
    url_ += 'SortDir=' + encodeURIComponent('' + body.sortDir) + '&';
  }
  url_ = url_.replace(/[?&]$/, '');
  return httpEventClient().get(url_).then((response: any) => {
    return { data: response.status == 200
      ? response.data
      : [], status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: [], status: error.status, statusText: error.statusText };
  });


}
export function getSitesInfoList(programId: number) {
  return httpPlannerClient().get(`${API_CONST.SITE_NOTIFICATION_INFO}?programId=${programId}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function deleteCollaboratorInViewTask(id: number) {
  return httpPlannerClient().delete(`${API_CONST.COLLABORATOR}?id=${id}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function addNewCollaboratorInViewTask(payload: IAddTaskCollaboratorDto) {
  return httpPlannerClient().put(`${API_CONST.TASK}${payload.taskId}/${API_CONST.COLLABORATOR}/${payload.collaboratorId}`).then((response: any) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error: any) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
export interface IOwnerInfoDto {
    programId: number;
    productId: number;
 }

export interface ISkipTaskDto {
  taskIds: number[] | undefined;
  status?: number;
 }
export interface IUpdateTaskOwnerDto {
    id:number;
    ownerId:number;
    previousOwnerId:number;
    siteId:number;
    status:number;
    programId:number;
 }
export interface IUpdateTaskStatusDto {
    id:number;
    status:number;
 }
export interface ICopyTaskDto {
  taskId: number;
  siteId: number;
  programId: number;
}
export interface IGetTaskDto {
    programId: number;
    productId: number;
    id?: number;
    active?:number;
    search?: string;
    page?: number;
    count?: number;
    sortField?: string;
    sortDir?: number;
    siteId?: number;
    taskUsers?: [
        {
          id: number;
          taskId: number;
          userId: number;
          isOwner: boolean;
          productProgramId: number;
          user: {
            id: number;
            firstName: string;
            lastName: string;
            email: string;
            userRoles: [
              {
                roleId: number;
                accountId: number;
                programId: number;
                siteId: number;
              }
            ];
            profilePicture: [
              {
                imageURL: string;
                thumbnailURL: string;
              }
            ];
          };
        }
    ];
}

export class TaskSiteAssignDto implements ITaskSiteAssignDto {
    taskIds?: number[] | undefined;
    sites?: number[] | undefined;

    constructor(data?: ITaskSiteAssignDto) {
      if (data) {
        for (const property in data) {
          if (data.hasOwnProperty(property)) {
            (<any>this)[property] = (<any>data)[property];
          }
        }
      }
    }

    init(_data?: any) {
      if (_data) {
        if (Array.isArray(_data['taskIds'])) {
          this.taskIds = [] as any;
          for (const item of _data['taskIds']) {
this.taskIds!.push(item);
          }
        }
        if (Array.isArray(_data['sites'])) {
          this.sites = [] as any;
          for (const item of _data['sites']) {
this.sites!.push(item);
          }
        }
      }
    }

    static fromJS(data: any): TaskSiteAssignDto {
      data = typeof data === 'object'
        ? data
        : {};
      const result = new TaskSiteAssignDto();
      result.init(data);
      return result;
    }

    toJSON(data?: any) {
      data = typeof data === 'object'
        ? data
        : {};
      if (Array.isArray(this.taskIds)) {
        data['taskIds'] = [];
        for (const item of this.taskIds) {
          data['taskIds'].push(item);
        }
      }
      if (Array.isArray(this.sites)) {
        data['sites'] = [];
        for (const item of this.sites) {
          data['sites'].push(item);
        }
      }
      return data;
    }
}

export interface ITaskSiteAssignDto {
    taskIds?: number[] | undefined;
    sites?: number[] | undefined;
}

export class AddTaskDto implements IAddTaskDto {
    programId?: number;
    programStartDate?: Date;
    programEndDate?: Date;
    productId?: number;

    constructor(data?: IAddTaskDto) {
      if (data) {
        for (const property in data) {
          if (data.hasOwnProperty(property)) {
            (<any>this)[property] = (<any>data)[property];
          }
        }
      }
    }

    init(_data?: any) {
      if (_data) {
        this.programId = _data['programId'];
        this.programStartDate = _data['programStartDate']
          ? new Date(_data['programStartDate'].toString())
          : <any>undefined;
        this.programEndDate = _data['programEndDate']
          ? new Date(_data['programEndDate'].toString())
          : <any>undefined;
        this.productId = _data['productId'];
      }
    }

    static fromJS(data: any): AddTaskDto {
      data = typeof data === 'object'
        ? data
        : {};
      const result = new AddTaskDto();
      result.init(data);
      return result;
    }

    toJSON(data?: any) {
      data = typeof data === 'object'
        ? data
        : {};
      data['programId'] = this.programId;
      data['programStartDate'] = this.programStartDate
        ? this.programStartDate.toISOString()
        : <any>undefined;
      data['programEndDate'] = this.programEndDate
        ? this.programEndDate.toISOString()
        : <any>undefined;
      data['productId'] = this.productId;
      return data;
    }
}

export interface IAddTaskDto {
    programId?: number;
    programStartDate?: Date;
    programEndDate?: Date;
    productId?: number;
}

export class TaskDto implements ITaskDto {
    id?: number;
    name?: string | undefined;
    cmsTaskId?: number | undefined;
    startDate?: Object | undefined;
    endDate?: Object | undefined;
    taskStartDate: Date = new Date();
    taskEndDate: Date = new Date();
    userId?: number;
    checked?: boolean;
    status?: number;
    isOwner?: boolean;

    constructor(data?: ITaskDto) {
      if (data) {
        for (const property in data) {
          if (data.hasOwnProperty(property)) {
            (<any>this)[property] = (<any>data)[property];
          }
        }
      }
    }

    init(_data?: any) {
      if (_data) {
        this.id = _data['id'];
        this.name = _data['name'];
        this.cmsTaskId = _data['cmsTaskId'];
        this.startDate = _data['startDate']
          ? _data['startDate']
          : <any>undefined;
        this.endDate = _data['endDate']
          ? _data['endDate']
          : <any>undefined;
        this.taskStartDate = _data['taskStartDate']
          ? new Date(_data['taskStartDate'].toString())
          : <any>undefined;
        this.taskEndDate = _data['taskEndDate']
          ? new Date(_data['taskEndDate'].toString())
          : <any>undefined;
        this.userId = _data['userId'];
        this.checked = _data['checked'];
        this.status = _data['status'];
        this.isOwner = _data['isOwner'];
      }
    }

    static fromJS(data: any): TaskDto {
      data = typeof data === 'object'
        ? data
        : {};
      const result = new TaskDto();
      result.init(data);
      return result;
    }

    toJSON(data?: any) {
      data = typeof data === 'object'
        ? data
        : {};
      data['id'] = this.id;
      data['name'] = this.name;
      data['cmsTaskId'] = this.cmsTaskId;
      data['startDate'] = this.startDate
        ? JSON.stringify(this.startDate)
        : <any>undefined;
      data['endDate'] = this.endDate
        ? JSON.stringify(this.endDate)
        : <any>undefined;
      data['taskStartDate'] = this.taskStartDate
        ? this.taskStartDate.toISOString()
        : <any>undefined;
      data['taskEndDate'] = this.taskEndDate
        ? this.taskEndDate.toISOString()
        : <any>undefined;
      data['userId'] = this.userId;
      data['checked'] = this.checked;
      data['status'] = this.status;
      data['isOwner'] = this.isOwner;
      return data;
    }
}

export interface ITaskDto {
    id?: number;
    name?: string | undefined;
    cmsTaskId?: number | undefined;
    startDate?: Object | undefined;
    endDate?: Object | undefined;
    taskStartDate: string | number | Date;
    taskEndDate: string | number | Date;
    userId?: number;
    checked?: boolean;
    status?: number;
    isOwner?: boolean;
}

export interface ITaskDetailDto {
        id: number;
        programId: number;
        siteId: number;
        name: string;
        startDate: Date | string;
        endDate: Date | string;
        status: number;
        lastEditedBy: number;
        lastEditedOn: Date | string;
        ownerAvailable?: boolean;
        taskNote?: {
          id?: number;
          taskId?: number;
          taskNote?: string;
        };
        taskDiscussions: [
          {
            id?: number;
            taskId?: number;
            comment?: string;
            userId?: number;
            createdOn: Date | string;
          }
        ];
        taskUsers: [
          {
            id: number;
            taskId: number;
            userId: number;
            isOwner: boolean;
            profilePicture?: [{ thumbnailUrl: string; imageUrl: string }];
            firstName?: string;
            lastName?: string;
            email?: string;
          }
        ];
        taskFiles: [
          {
            id: number;
            taskId: number;
            fileName: string;
            status: number;
            createdAt: string;
            createdBy: number;
            modifiedAt: string;
            modifiedBy: number;
            fileUrl: string;
            name?: string;
          }
        ];
        fileIds : [number];
        deletedFileIds: [number];
      }

export enum SortDirection {
    _0 = 0,
    _1 = 1,
    _2 = 2,
}

export interface IEventsData {
  title?: string;
  color?: string;
  calendar?: string;
  icon?: string;
  class?: string;
  id?: number;
  elementId?: string;
  timeDuration?: string;
}

export interface ISchedule {
      month?: number[];
      dayOfWeek?: number[];
      dayOfMonth?: number[];
      times?: string[];
      year?: number[];
      duration?: number;
      durationUnit?: string;
      weekspanOfMonth?: number[];
      lastDayOfMonth?: number[];
      lastWeekspanOfMonth?: number[];
}
export interface IEvents {
    data: IEventsData;
    schedule: ISchedule;
}
export interface IAddTaskCollaboratorDto {
  taskId:number;
  collaboratorId:number;
}