








































































import { ErrorPopUpComponent } from '@/commoncomponents/errorpopupcomponent/ErrorPopUpComponent';
export default ErrorPopUpComponent;
