import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgramStatusDropdown extends Vue {

    @Prop()
    programStatusDropdown!: [];

    @Prop()
    showStatusDD!:boolean;


    selectStatus(status:any){
      this.$emit('selectStatus',status);
    }
}