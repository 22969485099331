import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

@Component
export class DeleteRosterConfirmation extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @Prop()
    title! : string;

    @Prop()
    isShowStopPopup!:boolean;

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    closePopup() {
      this.$emit(APP_CONST.CLOSE);
    }
    deleteRoster() {
      this.$emit('deleteRoster');
        
    }

    stopRoster(){        
      this.$emit('stopRoster');
    }
   
    
   

}