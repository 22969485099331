import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import APP_UTILITIES from '@/utilities/commonFunctions';
import AUTH_UTILITIES from '@/utilities/authFunctions';
import { Vue } from 'vue-property-decorator';
import {uuid}  from 'vue-uuid';

const httpClient = ()=> {
  const httpInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json; multipart/form-data; charset=UTF-8',
      'Accept': 'application/json',
      'CorrelationId' : uuid.v1()
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
          return Promise.reject(error.response);
        }


        axiosInstance.interceptors.response.eject(interceptor);

      }
    );
  }

  createAxiosRequestInterceptor(httpInstance);
  createAxiosResponseInterceptor(httpInstance);
  return httpInstance;
};

const httpClientFileUploadFormData = ()=> {
  const httpInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
          return Promise.reject(error.response);
        }


        axiosInstance.interceptors.response.eject(interceptor);

      }
    );
  }

  createAxiosRequestInterceptor(httpInstance);
  createAxiosResponseInterceptor(httpInstance);
  return httpInstance;
};
const httpClientFormData = ()=> {
  const httpInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_PLANNER_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
          return Promise.reject(error.response);
        }


        axiosInstance.interceptors.response.eject(interceptor);

      }
    );
  }

  createAxiosRequestInterceptor(httpInstance);
  createAxiosResponseInterceptor(httpInstance);
  return httpInstance;
};
const httpPlannerClient = ()=> {
  const httpIdentityInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_PLANNER_URL,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
    }
  });
  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
        }
        axiosInstance.interceptors.response.eject(interceptor);
      }
    );
  }

  createAxiosRequestInterceptor(httpIdentityInstance);
  createAxiosResponseInterceptor(httpIdentityInstance);
  return httpIdentityInstance;
};

const httpEventClient = ()=> {
  const httpIdentityInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
        }
        axiosInstance.interceptors.response.eject(interceptor);
      }
    );
  }

  createAxiosRequestInterceptor(httpIdentityInstance);
  createAxiosResponseInterceptor(httpIdentityInstance);
  return httpIdentityInstance;
};

const httpCMSClient = () => {
  const httpCMSInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_CMS_URL,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
        }

        axiosInstance.interceptors.response.eject(interceptor);
      }
    );
  }

  createAxiosRequestInterceptor(httpCMSInstance);
  createAxiosResponseInterceptor(httpCMSInstance);
  return httpCMSInstance;
};

const httpIdentityClient = () => {
  const httpIdentityInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_IDENTITY_URL,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          APP_UTILITIES.removeCookies();
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
        }
        axiosInstance.interceptors.response.eject(interceptor);
      }
    );
  }

  createAxiosRequestInterceptor(httpIdentityInstance);
  createAxiosResponseInterceptor(httpIdentityInstance);
  return httpIdentityInstance;
};

const httpRosterClient = () => {
  const httpRosterInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_Roster_URL,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
        }

        axiosInstance.interceptors.response.eject(interceptor);
      }
    );
  }

  createAxiosRequestInterceptor(httpRosterInstance);
  createAxiosResponseInterceptor(httpRosterInstance);
  return httpRosterInstance;
};

const httpLMSClient = () => {
  const httpLMSInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_LMS_URL,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
        }

        axiosInstance.interceptors.response.eject(interceptor);
      }
    );
  }

  createAxiosRequestInterceptor(httpLMSInstance);
  createAxiosResponseInterceptor(httpLMSInstance);
  return httpLMSInstance;
};
const httpResource = () => {
  const httpResourceInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_PLANNER_URL,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
    },
    responseType: 'blob',
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
        }

        axiosInstance.interceptors.response.eject(interceptor);
      }
    );
  }


  createAxiosResponseInterceptor(httpResourceInstance);
  return httpResourceInstance;
};

const httpClientEnrollment = ()=> {
  const httpInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_ENROLLMENT_URL,
    headers: {
      'Content-Type': 'application/json; multipart/form-data; charset=UTF-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
          return Promise.reject(error.response);
        }


        axiosInstance.interceptors.response.eject(interceptor);

      }
    );
  }

  createAxiosRequestInterceptor(httpInstance);
  createAxiosResponseInterceptor(httpInstance);
  return httpInstance;
};


const httpEnrollmentClient = () => {
  const httpEnrollmentInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_ENROLLMENT_URL,
    headers: {
      'Content-Type': 'application/json; multipart/form-data; charset=UTF-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_ENROLLMENT_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
        }
        axiosInstance.interceptors.response.eject(interceptor);
      }
    );
  }

  createAxiosRequestInterceptor(httpEnrollmentInstance);
  createAxiosResponseInterceptor(httpEnrollmentInstance);
  return httpEnrollmentInstance;
};

const httpCMSAnalyticsClient = () => {
  const httpCMSAnalyticsInstance =  axios.create({
    baseURL: process.env.VUE_APP_BASE_ANALYTICS_CMS_URL,
    headers: {
      'Content-Type': 'application/json; multipart/form-data; charset=UTF-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });

  function createAxiosResponseInterceptor(axiosInstance : any) {
    const interceptor = axiosInstance.interceptors.response.use(
      (response : any) => response,
      (error : any) => {
        if (error.response.status !== 401 && error.response.status !== 500) {
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
          return Promise.reject(error.response);
        }
        else if(error.response.status === 401){
          window.location.href = process.env.VUE_APP_BASE_ENROLLMENT_REDIRECT_URL + '?type=logout';
        }
        else if(error.response.status === 401 && error.response.data !== ''){
          Vue.prototype.$eventHub.$emit('showErrorPopup', error.response.data);
        }
        else if(error.response.status === 500){
          const data = {
            name : 'Common_Error'
          };
          Vue.prototype.$eventHub.$emit('showErrorPopup', data);
        }
        axiosInstance.interceptors.response.eject(interceptor);
      }
    );
  }

  createAxiosRequestInterceptor(httpCMSAnalyticsInstance);
  createAxiosResponseInterceptor(httpCMSAnalyticsInstance);
  return httpCMSAnalyticsInstance;
};

function createAxiosRequestInterceptor(axios : AxiosInstance) {
  const requestInterceptor = axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const authToken = await AUTH_UTILITIES.getValidAuthToken();

    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${authToken}`
      }
    };
  }, function (error: AxiosError) {
    axios.interceptors.request.eject(requestInterceptor);
    return Promise.reject(error);
  });
}

export { httpClient,httpClientFormData, httpCMSClient, httpPlannerClient,httpEnrollmentClient,httpEventClient, httpIdentityClient, httpRosterClient, httpLMSClient, httpResource,httpClientFileUploadFormData, httpClientEnrollment,httpCMSAnalyticsClient};
