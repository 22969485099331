import { ParticipantHeaderConfig } from '@/Model/model';
import { httpClient, httpRosterClient, httpClientEnrollment } from '../common/httpClient';
import API_CONST from '@/constants/ApiConst';

export function downloadTemplate() {
  return httpClient().get(`${API_CONST.DOWNLOAD_TEMP}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function requiredHeaders() {
  return httpRosterClient().get(`${API_CONST.REQUIRED_HEADER}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function scholarStatus(siteId: number) {
  return httpClientEnrollment().get(`${API_CONST.CHECK_SCHOLAR_STATUS}?siteId=${siteId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getHeaders(siteId: number) {
  return httpClientEnrollment().get(`${API_CONST.HEADERS}?siteId=${siteId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function configureRoaster(payload: {}) {
  return httpClientEnrollment().post(`${API_CONST.CONFIGURE_ROASTER}`, payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


export function getTableDetails(stepObj:any) {
  return httpClientEnrollment().get(API_CONST.GET_TABLE_DATA+`?siteId=${stepObj.siteId}&id=${stepObj.id}&active=${stepObj.active}&search=${stepObj.search}&page=${stepObj.page}&count=${stepObj.count}&sortField=${stepObj.sortField}&sortDir=${stepObj.sortDir}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}


export function getAutoSaveTableDetails(stepObj:any) {
  return httpClientEnrollment().get(API_CONST.GET_ROSTER_AUTOSAVE+`?siteId=${stepObj.siteId}&step=${stepObj.stepNumber}&id=${stepObj.id}&active=${stepObj.active}&search=${stepObj.search}&page=${stepObj.page}&count=${stepObj.count}&sortField=${stepObj.sortField}&sortDir=${stepObj.sortDir}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function getAutoSaveStep2(payload: any) {
  return httpClientEnrollment().get(`${API_CONST.GET_ROSTER_AUTOSAVE}?siteId=${payload.siteId}&step=${payload.step}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function deleteRoasterAPI(siteId: any) {
  return httpClientEnrollment().delete(`${API_CONST.DELETE_ROASTER_DATA}?siteId=${siteId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function downloadRoaster(siteId: number, programId: number, step:number, fileType : number, sortingParam : any) {
  return httpClient().post(`${API_CONST.DOWNLOAD_ROASTER}?siteId=${siteId}&programId=${programId}&step=${step}&filetype=${fileType}&sortField=${sortingParam.sortField}&sortDir=${sortingParam.sortDir}`,
    {
      // It needs to be present in the request body
      ColumnsToHide: null
    }
  ).then((response) => {
    window.open(`${process.env.VUE_APP_BASE_URL}${API_CONST.DOWNLOAD_ROASTER}?siteId=${siteId}&programId=${programId}&step=${step}&filetype=${fileType}&sortField=${sortingParam.sortField}&sortDir=${sortingParam.sortDir}`, '_self');
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
export function deleteBulkRoaster(siteId:number) {
  return httpClient().delete(`${API_CONST.DELETE_BULK_ROSTER}?siteId=${siteId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function stopUpload(siteId:number) {
  return httpClientEnrollment().put(`${API_CONST.CANCEL_SITE}/${siteId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

/**
 * Retrieves the header config for participant table.
 *
 * @param {string} accountId - The current session's account id.
 * @returns {any} The header config for the participant table.
 */
export function getParticipantHeaderConfig(accountId: string) {
  return httpClientEnrollment()
    .get<ParticipantHeaderConfig>(`${API_CONST.GET_PARTICIPANTS_COLUMN_CONFIG}?accountId=${accountId}&isSubmission=false&isParticipant=true`);
}
